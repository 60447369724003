import React, { useRef, useEffect } from 'react';
import { OptionTypeBase } from 'react-select';
import Select, { Props as CreatableProps } from 'react-select/async-creatable';
import { useField } from '@unform/core';
// import { mensagemErro } from './../../components/Layout'
import api from './../../api'

function SelectCreate({ name, titulo, col, router, labelValue = '', ...props }) {
    const selectRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            path: 'select.state.value',
            getValue: (ref) => {
                if (props.isMulti) {
                    if (!ref.select.state.value) {
                        return [];
                    }

                    return ref.select.state.value.map(
                        (option) => option.value,
                    );
                } else {
                    if (!ref.select.state.value) {
                        return '';
                    }

                    return ref.select.state.value.value;
                }
            },
            setValue: (ref, value) => {
                ref.select.select.select.setValue(value);
            }
        });
    }, [fieldName, registerField, props.isMulti]);

    async function onSearchData(seachText) {

        if (router) {

            const resp = await api.post(router, {
                text: seachText
            })

            if (resp.data.type === 2) {

                // mensagemErro(`${resp.data.erro.message}. - #serv`)

                console.clear()
                console.log(resp.data.value)                

                return
            }

            if (resp.status === 200) {
                return resp.data.value
            }
            else {
                return []
            }
        }
    }

    return (
        <div className={`cInputD selectD ${col}`} classID={labelValue}>
            <span>{titulo}</span>
            <Select
                cacheOptions
                defaultValue={defaultValue}
                ref={selectRef}
                classNamePrefix="react-select"
                placeholder='Selecionar'
                noOptionsMessage={() => 'Sem dados'}
                loadOptions={onSearchData}
                defaultOptions
                formatCreateLabel={text => `Criar ${text}`}
                {...props}
            />
            {error && <span className="error-validacao">{error}</span>}
        </div>
    );
};

export default SelectCreate;