// src/pages/Noticias/index.js
import React, { useState, useEffect, useRef, useCallback } from 'react'
import './style.scss'

import { ContainerModal } from './../../components/Layout'
import { SelectAsync, ValidacaoYup, Input, TextRich, Data, Select } from './../../components/Form'
import FilterSelect from '../../components/Form/FilterSelect';
import FilterDate from '../../components/Form/FilterDate';

function Noticias() {
    const [imgMiniPreview, setImgMiniPreview] = useState(null);
    const [imgPreview, setImgPreview] = useState(null);
    const [imgMini, setImgMini] = useState(null);
    const [img, setImg] = useState(null);
    const formRef = useRef(null);
    const [filterType, setFilterType] = useState(null);
    const [filterStartDate, setFilterStartDate] = useState(null);
    const [filterEndDate, setFilterEndDate] = useState(null);
    const [selectedHomeType, setSelectedHomeType] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const searchTimeout = useRef(null);

    const cleanupURLs = () => {
        if (imgMiniPreview) {
            URL.revokeObjectURL(imgMiniPreview);
        }
        if (imgPreview) {
            URL.revokeObjectURL(imgPreview);
        }
    };

    useEffect(() => {
        return () => {
            cleanupURLs();
        };
    }, []);

    const HOME_TYPE_FIELD_CONFIG = {
        'Destaques': ['link', 'file'],
        'Vídeo de destaque': ['text_mini', 'text', 'img_mini', 'img', 'order', 'file'],
        'News': ['link', 'file'],
        'Comunicados': ['link', 'file'],
        'Nosso voo': ['link', 'file', 'text_mini'],
        'Eventos e Treinamentos': ['file'],
        'Biblioteca do Conhecimento': ['text', 'img_mini', 'img', 'file'],
        'Departamento Pessoal': ['text_mini', 'text', 'img_mini', 'img', 'link'],
    };

    const shouldHideField = (fieldName) => {
        if (!selectedHomeType) return false;
        return HOME_TYPE_FIELD_CONFIG[selectedHomeType]?.includes(fieldName);
    };
      
      

    const onEditarAfter = (data, setDataEdit, setLoadFull, setModal) => {
        setImgMiniPreview(null);
        setImgPreview(null);
        if (data.img_mini_url) {
            setImgMiniPreview(data.img_mini_url);
        }
        if (data.img_url) {
            setImgPreview(data.img_url);
        }
        
        // Formatando o valor da ordem para o formato esperado pelo Select
        if (data.order) {
            data.order = {
                value: data.order,
                label: `${data.order}`
            };
        }
        
        setDataEdit(data);
        setLoadFull(false);
        setModal(true);
    };

    const onNovoBefore = (setDataEdit) => {
        setImgPreview(null);
        setImgMiniPreview(null);
        setDataEdit({});
    };

    const adjustDate = (dateString) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        // Set to start of day in local timezone
        date.setHours(0, 0, 0, 0);
        return date.toISOString();
    };

    const onSaveBefore = (data) => {
        if (data.home_type_id && typeof data.home_type_id === 'object') {
            data.home_type_id = data.home_type_id.value;
        }

        if (imgMini) {
            data.img_mini = imgMini;
        }

        if (img) {
            data.img = img;
        }

        if (data.expires_in != null) {
            data.expires_in = adjustDate(data.expires_in);
        }

        if (data.data_publicacao != null) {
            data.data_publicacao = adjustDate(data.data_publicacao);
        }

        if (selectedFile) {
            data.file = selectedFile;
        }

        return data;
    };

    const orderOptions = Array.from({length: 20}, (_, i) => ({
        value: i + 1,
        label: `${i + 1}`
    }));

    const getFilterParams = () => ({
        home_type_id: filterType?.value || null,
        data_publicacao_inicio: filterStartDate ? filterStartDate.toISOString() : null,
        data_publicacao_fim: filterEndDate ? filterEndDate.toISOString() : null,
        search: searchTerm || null
    });

    useEffect(() => {
        if (formRef.current && formRef.current.reload) {
            // Only reload if we have either type or both dates
            if (filterType || (filterStartDate && filterEndDate) || searchTerm) {
                formRef.current.reload();
            }
        }
    }, [filterType, filterStartDate, filterEndDate, searchTerm]);

    const handleTypeChange = (value) => {
        setFilterType(value);
    };

    const handleDateRangeChange = (start, end) => {
        setFilterStartDate(start);
        setFilterEndDate(end);
        
        // Only trigger search if both dates are selected
        if (start && end && formRef.current && formRef.current.reload) {
            formRef.current.reload();
        }
    };

    const handlePageChange = (newPage) => {
        const validPage = (newPage);
        setCurrentPage(validPage);
    };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        
        // Clear existing timeout
        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }

        // Set new timeout
        searchTimeout.current = setTimeout(() => {
            if (formRef.current && formRef.current.reload) {
                formRef.current.reload();
            }
        }, 500); // 500ms delay
    };

    const renderFilters = () => (
        <div className="row">
            <div className="col-md-3">
                <FilterSelect
                    value={filterType}
                    onChange={handleTypeChange}
                    router="home-types"
                    placeholder="Filtrar por tipo"
                />
            </div>
            <div className="col-md-5">
                <FilterDate
                    startDate={filterStartDate}
                    endDate={filterEndDate}
                    onChangeRange={handleDateRangeChange}
                    placeholder="Filtrar por período"
                />
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Buscar por título..."
                        style={{ backgroundColor: 'white' }}
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <>
            <ContainerModal
                route='home-contents'
                title='Notícias'
                showButtonPagination={false}
                useFormData={true}
                showSearch={false}
                validacao={{
                    home_type_id: ValidacaoYup.string().required(),
                    title: ValidacaoYup.string().required(),
                }}
                showTopNavigation={false}
                campos={[
                    { nome: 'Tipo' },
                    { nome: 'Título' },
                    { nome: 'Data de Publicação' },
                    { nome: 'Data de Expiração' },
                    { nome: 'Status' }
                ]}
                onSaveBefore={onSaveBefore}
                onEditarAfter={onEditarAfter}
                onNovoBefore={onNovoBefore}
                formRef={formRef}
                filterParams={getFilterParams()}
                filterComponent={renderFilters()}
                useArgonPagination={true}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                setTotalPages={setTotalPages}
            >
                <SelectAsync 
                    name='home_type_id' 
                    col='col-lg-12' 
                    titulo='Tipo de Notícia' 
                    router='home-types'
                    onChange={(option) => setSelectedHomeType(option?.label)}
                />
                <Input 
                    name='title' 
                    col='col-lg-12'
                    titulo='Título'
                    placeholder='Digite o título da notícia'
                />
                {!shouldHideField('text_mini') && (
                    <TextRich 
                        name='text_mini' 
                        col='col-lg-12' 
                        titulo='Texto Resumido'
                        height="100px"
                    />
                )}

                {!shouldHideField('text') && (
                    <TextRich 
                        name='text' 
                        col='col-lg-12' 
                        titulo='Texto Completo'
                        height="500px"
                    />
                )}
                <div className="row">
                    {!shouldHideField('img_mini') && (
                        <div className="col-lg-6">
                            <Input 
                                name='img_mini' 
                                col='col-lg-12' 
                                titulo='Imagem Miniatura' 
                                type='file'
                                accept='image/*'
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                        if (imgMiniPreview) {
                                            URL.revokeObjectURL(imgMiniPreview);
                                        }
                                        const objectUrl = URL.createObjectURL(file);
                                        setImgMiniPreview(objectUrl);
                                        setImgMini(file);
                                    }
                                }}
                            />
                            {imgMiniPreview && (
                                <div className="mt-2" style={{ 
                                    display: 'flex', 
                                    justifyContent: 'center' 
                                }}>
                                    <img 
                                        src={imgMiniPreview} 
                                        alt="Preview" 
                                        style={{ 
                                            maxWidth: '200px', 
                                            maxHeight: '200px',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                            border: '1px solid #ddd'
                                        }} 
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {!shouldHideField('img') && (
                        <div className="col-lg-6">
                            <Input 
                                name='img' 
                                col='col-lg-12' 
                                titulo='Imagem Principal' 
                                type='file'
                                accept='image/*'
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                        if (imgPreview) {
                                            URL.revokeObjectURL(imgPreview);
                                        }
                                        const objectUrl = URL.createObjectURL(file);
                                        setImgPreview(objectUrl);
                                        setImg(file);
                                    }
                                }}
                            />
                            {imgPreview && (
                                <div className="mt-2" style={{ 
                                    display: 'flex', 
                                    justifyContent: 'center' 
                                }}>
                                    <img 
                                        src={imgPreview} 
                                        alt="Preview" 
                                        style={{ 
                                            maxWidth: '200px', 
                                            maxHeight: '200px',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                            border: '1px solid #ddd'
                                        }} 
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <Data 
                            name='data_publicacao' 
                            col='col-lg-12' 
                            titulo='Data de Publicação'
                        />
                    </div>
                    <div className="col-lg-6">
                        <Data 
                            name='expires_in' 
                            col='col-lg-12' 
                            titulo='Data de Expiração'
                        />
                    </div>
                </div>
                <div className="row">
                    {!shouldHideField('file') && (
                        <div className="col-lg-6">
                            <Input 
                                name='file' 
                                col='col-lg-12' 
                                titulo='Arquivo'
                                type='file'
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                        setSelectedFile(file);
                                    }
                                }}
                            />
                        </div>
                    )}
                    {!shouldHideField('order') && (
                        <div className="col-lg-6">
                            <Select 
                                name='order' 
                                col='col-lg-12' 
                                titulo='Ordem'
                                options={orderOptions}
                                placeholder="Selecione a ordem"
                            />
                        </div>
                    )}
                </div>
                {!shouldHideField('link') && (
                    <Input 
                        name='link' 
                        col='col-lg-12' 
                        titulo='Link Externo'
                    />
                )}
                
                

            </ContainerModal>
        </>
    )
}

export default Noticias