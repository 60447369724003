import React from 'react'

function Card({ nome,
    classAdd = 'homeCardClass', // classa adicional
    buttons, // Array , adiciona novos botões 
    col = 'col-xl-12', // tamanho da tela
    header = '', // Texto do header
    children
}) {

    return (
        <>
            <div className={`${classAdd} ${col}`} style={{ marginTop: 0 }}>
                <div className="row">
                    <div className="col">
                        <div className="card">
                            <div className="card-header border-0">
                                <h3 className="mb-0">{nome}</h3>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {
                                        buttons &&
                                        buttons.map((button, index) => {
                                            return (
                                                <div key={index} style={{ marginLeft: 6 }}>
                                                    {button}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="table-responsive">
                                {
                                    header.length > 0 &&
                                    <div className='info-header'>
                                        <span>
                                            {header}
                                        </span>
                                    </div>
                                }
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Card