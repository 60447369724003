import React from 'react'

import { FiAward, FiAlertTriangle, FiUsers, FiBriefcase, FiFileText, FiDownload } from 'react-icons/fi'

function Counters({ counters }) {
    return (
        <>
            <div className='container-fluid' style={{ marginTop: -126 }}>
                <div className="row">
                    <div className="col-xl-4 col-md-6">
                        <div className="card card-stats card-ajust-bottom">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Documento</h5>
                                        <span className="h2 font-weight-bold mb-0"><a href={counters?.documento1.url} target="_blank">{counters?.documento1.nome}</a> </span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow bg-atrasados">
                                            <FiDownload />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="card card-stats card-ajust-bottom">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Documento</h5>
                                        <span className="h2 font-weight-bold mb-0"><a href={counters?.documento2.url} target="_blank">{counters?.documento2.nome}</a> </span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow bg-pendente">
                                            <FiDownload />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-3 col-md-6">
                        <div className="card card-stats card-ajust-bottom">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Documento</h5>
                                        <span className="h3 font-weight-bold mb-0"><a href="https://github.com/">Relatório de Viagem</a> </span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow bg-lancados">
                                            <FiDownload />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-xl-4 col-md-6">
                        <div className="card card-stats card-ajust-bottom">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h5 className="card-title text-uppercase text-muted mb-0">Aniversáriantes</h5>
                                        <span className="h2 font-weight-bold mb-0">{counters.aniversarianteTotal}</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow bg-concluido">
                                            <FiAward />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Counters