import React, { useState, useEffect } from 'react'
import './style.scss'
import { PowerBIEmbed } from 'powerbi-client-react';
import { useAuth } from './../../context/Auth'
import { models } from 'powerbi-client';

import { Container } from './../../components/Layout'

import api from './../../api'


function PagamentoAplicativo() {

    const { getUser } = useAuth()
    const usuario = getUser()
    // PowerBI Report object (to be received via callback)
    const [report, setReport] = useState();
    // API end-point url to get embed config

    // Report config useState hook
    // TODO useEffect calling singIn
    const [reportConfig, setReportConfig] = useState({
    });

    // Map of event handlers to be applied to the embedding report
    const eventHandlersMap = new Map([
        ['loaded', function () {
            console.log('Report has loaded');
        }],
        ['rendered', function () {
            console.log('Report has rendered');
        }],
        ['error', function (event) {
            if (event) {
                console.error(event.detail);
            }
        }]
    ]);

    useEffect(() => {
        // Fetch sample report's config (eg. embedUrl and AccessToken) for embedding
        const signIn = (async () => {
            const _reportConfig = await api.post('bis/embeddedReportApp', {})
            // console.log(_reportConfig)

            const filter = {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                    table: "ES_VW_Consultor",
                    column: "Nom_Consultor"
                },
                filterType: 1,
                displaySettings: {
                    isHiddenInViewMode: true
                },
                operator: "In",
                values: [
                    usuario.nome_consultor
                ],
                requireSingleSelection: false
            };

            console.log(filter)
            // Set the fetched embedUrl and embedToken in the report config
            setReportConfig(prevState => ({
                ...prevState,
                type: 'report',
                tokenType: models.TokenType.Embed,
                embedUrl: _reportConfig.data.EmbedUrl,
                accessToken: _reportConfig.data.EmbedToken,
                settings: {
                    filterPaneEnabled: true
                },
                filters: [filter]
            }));
        });

        signIn();


    }, [usuario.nome_consultor]);


    return (
        <>
            <Container
                classAdd='semHomeClass'
                showSearch={false}
            >
                {reportConfig.embedUrl &&

                    <div>
                        <PowerBIEmbed
                            embedConfig={reportConfig}
                            eventHandlers={eventHandlersMap}
                            cssClassName={"report-style-class"}
                            getEmbeddedComponent={(embedObject) => {
                                console.log(`Embedded object of type "${embedObject.embedtype}" received`);
                                setReport(embedObject)
                            }}

                        />
                    </div>
                }
            </Container>
        </>
    )
}

export default PagamentoAplicativo