import React from 'react'
import Select from './Select'

function UF() {

    const optionsUF = [
        { value: 'RO', label: 'RO' },
        { value: 'AC', label: 'AC' },
        { value: 'AM', label: 'AM' },
        { value: 'RR', label: 'RR' },
        { value: 'PA', label: 'PA' },
        { value: 'AP', label: 'AP' },
        { value: 'TO', label: 'TO' },
        { value: 'MA', label: 'MA' },
        { value: 'PI', label: 'PI' },
        { value: 'CE', label: 'CE' },
        { value: 'RN', label: 'RN' },
        { value: 'PB', label: 'PB' },
        { value: 'PE', label: 'PE' },
        { value: 'AL', label: 'AL' },
        { value: 'SE', label: 'SE' },
        { value: 'BA', label: 'BA' },
        { value: 'MG', label: 'MG' },
        { value: 'ES', label: 'ES' },
        { value: 'RJ', label: 'RJ' },
        { value: 'SP', label: 'SP' },
        { value: 'PR', label: 'PR' },
        { value: 'SC', label: 'SC' },
        { value: 'RS', label: 'RS' },
        { value: 'MS', label: 'MS' },
        { value: 'MT', label: 'MT' },
        { value: 'GO', label: 'GO' },
        { value: 'DF', label: 'DF' },
    ]

    return <Select name='UF' titulo='UF' options={optionsUF} />
}

export default UF