import React, { useState, useEffect } from 'react'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid'
import ptBR from "@fullcalendar/core/locales/pt-br";

import { Container, Modal } from './../../components/Layout'
import { Select, Input, Form, ButtonSave, SelectAsync, Textarea, Data } from './../../components/Form'
import { useLayout } from './../../context/Layout'

import moment from 'moment'
import api from './../../api'

import { FiPlusCircle } from 'react-icons/fi'

import './style.scss';

function App() {

    const calendarRef = React.createRef();

    const { setLoadFull } = useLayout()

    const [eventos, setEventos] = useState()
    const [showModal, setShowModal] = useState(false)

    const [dataEditar, setDataEditar] = useState({})

    useEffect(() => {
        const startOfMonth = moment().startOf('month').format('YYYYMMDD')
        onBuscaDados(startOfMonth)
    }, [])

    async function onBuscaDados(data) {
        setLoadFull(true)
        const resp = await api.post('agendas/calendario', { dataInicial: data })
        setEventos(resp.data)
        setLoadFull(false)
    }

    function ButtonNovo() {

        function onNovo() {

            setDataEditar({
                interesse: { value: 'S', label: 'Sim' }
            })
            setShowModal(true)
        }

        return (
            <div onClick={onNovo} data-rh={`Novo visto`}>
                <FiPlusCircle />
            </div>
        )
    }

    function onSaveModal() {

    }

    return (
        <>
            <Container
                classAdd='semHomeClass'
            >
                <div className="App">
                    <FullCalendar defaultView="dayGridMonth"
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        dateClick={handleDateClick}
                        ref={calendarRef}
                        header={{
                            left: 'today',
                            center: 'previousMonth title nextMonth',
                            right: 'dayGridMonth'
                        }}
                        buttonText={{
                            list: 'Dia',
                        }}
                        customButtons={{
                            previousMonth: {
                                click: () => {
                                    handlePreviousMonth();
                                }
                            },
                            nextMonth: {
                                click: () => {
                                    handleNextMonth();
                                }
                            }
                        }}
                        buttonIcons={{
                            previousMonth: 'chevron-left',
                            nextMonth: 'chevron-right'
                        }}
                        initialView='dayGridMonth'
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        weekends={true}
                        fullscreen={false}
                        events={eventos}
                        // select={this.handleDateSelect}
                        eventContent={renderEventContent} // custom render function
                        eventClick={handleEventClick}
                        eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                        locale={ptBR} />
                </div>
            </Container>

            {
                showModal &&
                <Modal
                    title='Inserir Compromisso'
                    showModal={showModal}
                    onClose={() => setShowModal(false)}
                    onSave={onSaveModal}
                    validacao={{}}
                    data={dataEditar}
                >

                    <SelectAsync name='cod_prop_ativ' col='col-lg-12' titulo='Projeto' labelValue='nome_projeto' router='agendas/comboProjeto' />

                    <SelectAsync name='cod_grupo' col='col-lg-12' titulo='Atuação' labelValue='nome_atuacao' router='agendas/comboAtuacao' />

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Input name='dias_previstos' col='col-lg-6' titulo='Dias Previstos' placeholder='0' disabled />
                        <Input name='dias_agendados' col='col-lg-6' titulo='Dias Agendados' placeholder='0' disabled />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <Data name='data_inicial' col='col-lg-6' titulo='Data Inicial' />
                        <Data name='data_final' col='col-lg-6' titulo='Data Final' />
                    </div>

                    <SelectAsync name='chor_esc' col='col-lg-12' titulo='Disponibilidade' labelValue='nome_disponibilidade' router='agendas/comboDisponibilidade' />

                    <SelectAsync name='cod_mun' col='col-lg-12' titulo='Município' labelValue='nome_municipio' router='agendas/comboMunicipio' />

                    <Textarea name='obs_esc' col='col-lg-12' titulo='Observação' placeholder='Digite a Observação' rows={3} />

                </Modal>
            }
        </>
    );

    async function handlePreviousMonth() {
        let calendarApi = calendarRef.current.getApi();
        calendarApi.prev();
        console.log(calendarApi.getDate().toISOString());

        let dataMesAnterior = moment(calendarApi.getDate().toISOString()).format('YYYYMMDD')
        console.log(dataMesAnterior)
        onBuscaDados(dataMesAnterior)
    };

    async function handleNextMonth() {
        let calendarApi = calendarRef.current.getApi();
        calendarApi.next();
        console.log(calendarApi.getDate().toISOString());

        let dataMesPosterior = moment(calendarApi.getDate().toISOString()).format('YYYYMMDD')
        console.log(dataMesPosterior)
        onBuscaDados(dataMesPosterior)
    };

    async function handleDateSelect(selectInfo) {
        console.log('handleDateSelect');
        let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar

        calendarApi.unselect() // clear date selection

        if (title) {
            calendarApi.addEvent({
                id: Math.random(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            })
        }
    }

    async function handleDateClick(selectInfo) {

        // let title = prompt('Entre com as informações da nova escala')
        // let calendarApi = selectInfo.view.calendar

        // if (title) {
        //     calendarApi.addEvent({
        //         title,
        //         start: selectInfo.dateStr,
        //     })
        // }

        setShowModal(true)
    };

    async function handleEventClick(clickInfo) {

        const resp = await api.post('agendas/edit', {
            parametroData: clickInfo.event.start,
            parametroCodigo: clickInfo.event.id
        })

        setShowModal(true)
    }

    async function handleEvents(events) {

        console.log('handleEvents')

        this.setState({
            currentEvents: events
        })
    }

    function renderEventContent(eventInfo) {
        return (
            <>
                <b>{eventInfo.timeText}</b>
                <i>{eventInfo.event.title}</i>
            </>
        )

    }
}

export default App;
