// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/bg_login.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login-button {\n  background-color: var(--color-primary) !important;\n  border-color: var(--color-primary) !important;\n}\n\n.login-fundo {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n}\n\n.famdim-login {\n  border: 1px solid #ebeff1 !important;\n  border-radius: 8px !important;\n}\n.famdim-login img {\n  width: 100% !important;\n}\n\n.famdim-button-login-div-semconta {\n  background: transparent !important;\n  border: 0px;\n  color: var(--color-primary);\n  width: 100%;\n  font-size: 10px;\n  margin-top: -10px;\n  box-shadow: none;\n  margin-bottom: -30px;\n  text-transform: uppercase;\n}\n.famdim-button-login-div-semconta:hover {\n  background: transparent !important;\n  color: #b5bcc2;\n  box-shadow: none;\n}\n\n.famdim-footer-login {\n  margin: 10px 0;\n  display: flex;\n  justify-content: center;\n  padding: 0 16px;\n  background: #f8f9fe !important;\n}\n\n.famdim-esqueci-senha {\n  color: #adb5bd;\n  font-size: 12px;\n  display: flex;\n  justify-content: center;\n}\n.famdim-esqueci-senha:hover {\n  color: var(--color-primary);\n}", ""]);
// Exports
module.exports = exports;
