import React from 'react'

function Table({ campos = [], trs }) {

    return (
        <table className="table align-items-center table-flush">
            <thead className="thead-light">
                <tr>
                    {
                        campos && campos.map((campo, index) => {
                            return <th key={index} scope="col" style={{ width: campo.tamanho ? campo.tamanho : 0, paddingRight: -10 }}>{campo.nome}</th>
                        })
                    }
                </tr>
            </thead>
            <tbody className="list">
                {trs()}
            </tbody>
        </table>
    )
}

export default Table