import React, { useRef, useEffect } from 'react'
import './style.scss'

import { ButtonSave, ButtonSair, Form, Input, ButtonDelete } from './../../Form'
import { FiXCircle } from 'react-icons/fi'
import { useLayout } from './../../../context/Layout'

function Modal({
    title,
    load,
    showModal,
    onClose,
    onSave,
    onDelete,
    validacao,
    data,
    nomeButtonSave = 'Salvar',
    children,
    showButtonSave = true,
    showButtonDelete = false,
    setWidth = '50%'
}) {

    const formRef = useRef()
    const { modalIsOpen } = useLayout()


    useEffect(() => {

        if (showModal)
            modalIsOpen(true)

        if (data && formRef?.current) {

            formRef.current.reset()
            if (data) {
                formRef.current.setData(data)
            }
        }

    }, [])

    function _onCloseModal() {

        modalIsOpen(false)
        onClose()
    }

    return (
        <>
            <Form setRef={formRef} onSubmit={onSave} validacao={validacao} >
                <div className="modal fade show">
                    <div className="modal-dialog" style={{ width: setWidth }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myLargeModalLabel">{title}</h5>
                                <div className='divClose' onClick={_onCloseModal}>
                                    <FiXCircle />
                                </div>
                            </div>
                            <div className="modal-body">

                                <div className="row">
                                    <Input name='id' hidden col='col-lg-12' />
                                </div>

                                {children}
                            </div>
                            <div className="modal-footer">
                                <ButtonSair name='Sair' onClick={_onCloseModal} />
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {
                                        showButtonDelete &&
                                        <ButtonDelete name='Excluir' onClick={() => onDelete(data)} />
                                    }
                                    {
                                        showButtonSave &&
                                        <ButtonSave load={load} disabled={load} name={nomeButtonSave} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default Modal