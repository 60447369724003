import React, { useState } from 'react'
import './style.scss'

import { Redirect } from 'react-router-dom'
import { useAuth } from './../../context/Auth'
import ReactLoading from 'react-loading'

import api from './../../api'
import Colors from './../../styles/Colors'

import { Link } from 'react-router-dom'

function Login() {

    const { setUser, getUser } = useAuth()
    const usuario = getUser()

    const [load, setLoad] = useState(false)
    const [validacao, setValidacao] = useState('')

    const [logado, setLogado] = useState(false)
    // const [email, setEmail] = useState('rodrigo.torquetti')
    // const [senha, setSenha] = useState('Aquila*2019')
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')

    async function onLogin() {

        if (email.length === '') {
            setValidacao('Preencha o login para continuar')
            return
        }

        if (senha.length === 0) {
            setValidacao('Preencha a senha para continuar')
            return
        }

        setLoad(true)
        const resp = await api.post('users/login', { username: email, password: senha });

        if (resp.status === 200) {

            if (resp.data.type === 1) {
                setValidacao(resp.data.message)
                setLoad(false)
                return
            }

            const { id, nome, email, token, cod_consultor, nome_consultor, perfil_consultor } = resp.data.value
            api.defaults.headers.Authorization = `Bearer ${token}`
            // console.log({ id, nome, email, token, cod_consultor, nome_consultor, perfil_consultor })

            try {
                const ipResponse = await fetch('https://api.ipify.org?format=json');
                const ipData = await ipResponse.json();
                const ip = ipData.ip;
    
                setUser({
                    id,
                    nome,
                    email,
                    token,
                    cod_consultor,
                    nome_consultor,
                    perfil_consultor,
                    ip, // Adicione o IP aqui
                });
            } catch (error) {
                console.error('Erro ao obter o IP do usuário:', error);
                setUser({
                    id,
                    nome,
                    email,
                    token,
                    cod_consultor,
                    nome_consultor,
                    perfil_consultor,
                    ip: '', // Defina IP vazio em caso de erro
                });
            }

            setLogado(true)
        }

        setLoad(false)
    }

    return (
        <>
            {
                logado || usuario ? <Redirect to={{ pathname: '/newslist' }} /> :
                    <div className="main-content login-fundo" style={{ minHeight: '100vh' }}>
                        <div className="container pb-5" style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="row justify-content-center">
                                <div className="col-lg-8 col-md-7" style={{ minWidth: 380 }}>
                                    <div className="card bg-secondary border-0 mb-0 famdim-login">
                                        <div className="card-body px-lg-5 py-lg-5" style={{ backgroundColor: '#f8f9fe' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 30 }}>
                                                <img style={{ width: '100%' }} src='logo-intranet.png' />
                                            </div>
                                            <form role="form">
                                                <div className="form-group mb-3">
                                                    <div className="input-group input-group-merge input-group-alternative">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text"><i className="ni ni-single-02"></i></span>
                                                        </div>
                                                        <input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="username" type="email" />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="input-group input-group-merge input-group-alternative">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text"><i className="ni ni-lock-circle-open"></i></span>
                                                        </div>
                                                        <input value={senha} onChange={(e) => setSenha(e.target.value)} className="form-control" placeholder="password" type="password" />
                                                    </div>
                                                </div>

                                                <div className="text-center">

                                                    {validacao.length > 0 && <div className="validaRetorno">{validacao}</div>}

                                                    <button
                                                        style={{
                                                            backgroundColor: Colors.primary,
                                                            borderColor: Colors.primary,
                                                        }}
                                                        disabled={load}
                                                        onClick={onLogin}
                                                        type="button"
                                                        className="btn btn-primary">

                                                        {load ? <ReactLoading type='cylon' color='#FFF' height={30} width={30} /> : 'Entrar'}

                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                        <div className='famdim-footer-login'>
                                            <Link to='/esquecisenha' className='famdim-esqueci-senha'>Esqueci a senha</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }

        </>
    )
}

export default Login