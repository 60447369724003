import React from 'react'

import { AuthProvider } from './Auth'
import { LayoutProvider } from './Layout'

import Load from './../components/Layout/Load'

function Store({ children }) {

    return (
        <>
            <AuthProvider>
                <LayoutProvider>
                    <Load />
                    {children}
                </LayoutProvider>
            </AuthProvider>
        </>
    )
}

export default Store