// src/components/Layout/TopNavbar.js
import React, { useEffect, useState } from 'react';
import './TopNavbar.scss';

import { NavLink, Link, useHistory } from 'react-router-dom';

import { RiLockPasswordLine, RiUserLocationLine } from 'react-icons/ri';
import { useAuth } from './../../context/Auth';

const TopNavbar = () => {
  const { getUser, getNivel, removeUser } = useAuth();
  const history = useHistory();
  const user = getUser();
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [showPerfilOptions, setShowPerfilOptions] = useState(false);

  useEffect(() => {
    const str = getUser().nome_consultor;
    const index = str.indexOf(' ');
    const [first, second] = [str.slice(0, index), str.slice(index + 1)];

    setNome(first);
    setSobrenome(second);
  }, []);

  function onSair() {
    removeUser();
    history.push("/");
  }

  function onPerfil() {
    setShowPerfilOptions(!showPerfilOptions);
  }

  const baseMenuOptions = user.perfil_consultor === 'Desenvolvedor' ? [
  {
    path: '/arquivo',
    label: 'Arquivos'
  }
] : [
  {
    path: '/home',
    label: 'Jeito Aquila de Ser'
  },
  {
    path: '/agenda',
    label: 'Agenda'
  },
  {
    path: '/catalogo',
    label: 'Catálogo'
  },
  {
    path: '/pagamento',
    label: 'Pagamentos'
  },
  {
    path: '/projeto',
    label: 'Minha Programação'
  },
  {
    path: '/projetoequipe',
    label: 'Programação Equipe'
  },
  {
    path: '/newslist',
    label: 'Notícias'
  }
];

const menuOptions = [
  ...baseMenuOptions,
  ...(user.perfil_consultor === 'Marketing' ? [
    {
      path: '/noticias',
      label: 'Cad. Notícias'
    },
    {
      path:'/administrative-contacts',
      label: 'Contatos Adm.'
    }
  ] : [])
];

  return (
    <nav className="top-navbar">
      <div className="navbar-logo">
        <Link to="/newslist" className="navbar-brand">
          <img src="../logo_branca.png" alt="Logo" style={{ width: '187px', height: '65px' }} />
        </Link>
      </div>
      <ul className="navbar-menu">
        {menuOptions.map((option, index) => (
          <li key={index}>
            <NavLink to={option.path} activeClassName='active' className="nav-link">
              {option.icon}
              <span><b>{option.label}</b></span>
            </NavLink>
          </li>
        ))}
      </ul>
      <ul className="navbar-nav">
      </ul>
    </nav>
  );
};

export default TopNavbar;