import React from 'react'

function ButtonGenerico({ onClick, Icon, tooltip = '' }) {

    return (
        <div className='buttonTableClass' style={{ marginLeft: 10 }} data-rh={tooltip}>
            <Icon onClick={onClick} />
        </div>
    )
}

export default ButtonGenerico