import React, { useEffect, useRef, useState } from 'react';
import './style.scss'

import { useField } from '@unform/core';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import axios from 'axios'
import Loading from 'react-loading'

import { FiSearch } from 'react-icons/fi'

function CEP({ name, col, titulo, cidadekey = 'cidade_id', ...props }) {
    const inputRef = useRef(null);
    const { fieldName, registerField, defaultValue, error } = useField(name);
    const [loadBusca, setLoadBusca] = useState(false)

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            defaultValue: '',
            path: 'value',
            setValue(ref, value) {
                ref.setInputValue(value === null ? '' : value);
            },
            clearValue(ref) {
                ref.setInputValue('');
            },
        });
    }, [fieldName, registerField]);

    async function onBuscaCEP() {

        const cep = inputRef.current.value;

        if (cep.length < 9) return

        setLoadBusca(true)
        const dadosCep = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)

        const { localidade, uf, bairro, logradouro, complemento } = dadosCep.data

        if (document.querySelector('#cidade'))
            document.querySelector('#cidade').value = localidade === undefined ? '' : localidade

        if (document.querySelector('#bairro'))
            document.querySelector('#bairro').value = bairro === undefined ? '' : bairro

        if (document.querySelector('#rua'))
            document.querySelector('#rua').value = logradouro === undefined ? '' : logradouro

        if (document.querySelector('#complemento'))
            document.querySelector('#complemento').value = complemento === undefined ? '' : complemento

        if (document.querySelector('#uf'))
            document.querySelector('#uf').value = uf === undefined ? '' : uf

        setLoadBusca(false)
    }

    return (
        <div className={`cInputD ${col}`}>
            <span htmlFor={name}>{titulo}</span>
            <div className='divCep'>
                <div className="input-group input-group-merge input-group-alternative">
                    <ReactInputMask mask='99999-999' autoComplete='no' ref={inputRef} {...props} {...InputProps} defaultValue={defaultValue} />
                    {loadBusca ? <Loading type='spin' color='#CCC' height={20} width={20} className='searchCEPload' /> : <div className='divBuscaCep' onClick={onBuscaCEP}><FiSearch /></div>}
                    {/*  */}
                </div>
            </div>
            {error && <span className="error-validacao">{error}</span>}
        </div>
    );
}

export default CEP