import React from 'react'
import './style.scss'
import ReactLoading from 'react-loading'

import Colors from './../../../styles/Colors'

function Button({ load = false, disabled = false, name = 'SALVAR', icon, ...props }) {

    const styleButton = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.primary,
        borderColor: Colors.primary
    }

    return (
        <>
            <button disabled={load || disabled} {...props} className="btn btn-primary buttonEnviar" type="submit" style={styleButton} >
                {
                    load ?
                        <ReactLoading type='cylon' color='#FFF' height={30} width={30} />
                        :
                        <>
                            {
                                name
                            }
                            {
                                icon && icon
                            }
                        </>
                }
            </button>
        </>
    )
}

export default Button