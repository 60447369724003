import React from 'react'
import Dropzone from 'react-dropzone'

import { FiEye, FiTrash2, FiUpload } from 'react-icons/fi'

import './style.scss'

function Anexo({ files, setFiles }) {

    function onSelectImage(file) {        

        if (file.length > 0) {
            setFiles([...files, ...file])
        }
    }

    function onExcluiAnexo(_file) {

        const novosAnexos = files.filter(file => file !== _file)
        setFiles(novosAnexos)
    }

    return (
        <Dropzone
            accept={['.xlsx']}
            maxSize={15728640}
            multiple={true}
            onDrop={onSelectImage}
        >
            {({ getRootProps, getInputProps }) => (
                <>
                    <div {...getRootProps()} className='dropzone'>
                        <input {...getInputProps()} />                        
                        <FiUpload />
                        <p>Arraste e solte alguns arquivos aqui ou clique para selecionar os arquivos</p>
                        <p style={{ fontSize: 9 }}>São aceitos apenas XLS com até 15mb</p>
                    </div>
                    {
                        files && files.map((file, index) => {

                            return (
                                <div key={index} className='dropzone-item'>
                                    <span>{`${file.path}`}</span>
                                    <div>
                                        <a href={URL.createObjectURL(file)} target='_blank'>
                                            <FiEye style={{ marginLeft: 4 }} />
                                        </a>
                                        <FiTrash2 onClick={() => onExcluiAnexo(file)} />

                                    </div>
                                </div>
                            )
                        })
                    }
                </>
            )
            }
        </Dropzone>
    )
}

export default Anexo