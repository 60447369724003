import React from 'react'
import { toast, Slide } from 'react-toastify';

const mensagemErro = (mensagem) => {
    toast.error(mensagem ? mensagem : '  Algo de estranho aconteceu! #serv', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        transition: Slide
    });
}

const mensagemInfo = (mensagem) => {
    toast.info(mensagem, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        transition: Slide
    });
}

const mensagemWarning = (mensagem) => {
    toast.warning(mensagem, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        transition: Slide
    });
}

const mensagemSucesso = (mensagem) => {
    toast.success(mensagem, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        transition: Slide        
    });
}

const mensagem = (mensagem) => {
    toast(mensagem, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        transition: Slide
    });
}

export { mensagemErro, mensagemInfo, mensagemWarning, mensagemSucesso, mensagem }