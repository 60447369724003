import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../context/Auth';

function RouteGuard({ component: Component, isPublic = false, ...rest }) {
  const { getUser } = useAuth();
  const user = getUser();

  return (
    <Route
      {...rest}
      render={props => {
        // Check if user has correct profile
        // const hasValidProfile = user && (user.perfil_consultor === 'Desenvolvedor' || user.perfil_consultor === 'Marketing');
        const hasValidProfile = true

        // If it's not a public route and user is not logged in, redirect to login
        if (!isPublic && !user) {
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
        }

        // If user doesn't have valid profile, always redirect to maintenance
        if (user && !hasValidProfile && props.location.pathname !== '/manutencao') {
          return <Redirect to="/manutencao" />;
        }

        // Render component if all checks pass
        return <Component {...props} />;
      }}
    />
  );
}

export default RouteGuard;