import React from 'react'
import './style.scss'
import ReactLoading from 'react-loading'

function ButtonSaveNoSubmit({ load, name = 'SALVAR', icon, props }) {

    const styleButton = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#17416e',
        borderColor: '#17416e'
    }

    return (
        <>
            <button disabled={load ? load : false} {...props} className="btn btn-primary buttonEnviar" style={styleButton} >
                {
                    load ?
                        <ReactLoading type='cylon' color='#FFF' height={30} width={30} />
                        :
                        <>
                            {
                                name
                            }
                            {
                                icon && icon
                            }
                        </>
                }
            </button>
        </>
    )
}

export default ButtonSaveNoSubmit