import React, { useState, useEffect } from 'react'

import './style.scss'

import { Container, Card } from './../../components/Layout'
import { Table } from './../../components/Page'
import { useLayout } from './../../context/Layout'
import { useAuth } from './../../context/Auth'
import { MdForum } from 'react-icons/md'

import Counters from './Counters'

import api from './../../api'

function Home() {

    const { getUser } = useAuth()
    const user = getUser()
    const { setLoadFull, load } = useLayout()

    const [data, setData] = useState([])
    const [dataAniversariante, setDataAniversarianete] = useState([])
    const [dataAgenda, setDataAgenda] = useState([])

    const [dataForum, setDataForum] = useState([
        {
            data: '10/10/2020',
            forum: 'Como funciona um forum ?'
        },
        {
            data: '10/10/2020',
            forum: 'Existe função sobre essa especialidade'
        },
    ])

    const [dataDocumento, setDataDocumento] = useState([])

    const [counters, setCounters] = useState(null)

    function SemRegistro() {

        return (
            <div className='noFile'>
                <img src='assets/nofile/nofile.svg' />
                <span>SEM REGISTRO</span>
            </div>
        )
    }

    useEffect(() => {
        onBuscaDados()
    }, [])

    async function onBuscaDados() {

        setLoadFull(true)

        const resp = await api.post('homes/dados', {perfilConsultor: user.perfil_consultor })

        setDataAgenda(resp.data.agenda)

        setDataAniversarianete(resp.data.aniversariante)

        setCounters(resp.data.counters)

        setDataDocumento(resp.data.arquivo)

        setLoadFull(false)
    }

    return (
        <>
            <Container
                routerSearch='portal/pesquisa'
                setDataGrid={setData}
                showSearch={false}
            >
                {
                    counters ?
                        <Counters counters={counters} />
                        : ''
                }

                <div className='col-xl-8'>

                    {/* <Card
                        nome='Documentos'
                        classAdd='homeCardClassHome'
                        col='col-xl-12'
                    >

                        <Table
                            campos={[
                                { nome: 'Nome' },
                            ]}
                            trs={() => {

                                return dataDocumento.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.nome}</td>
                                        </tr>
                                    )
                                })
                            }}
                        />

                    </Card> */}

                    <Card
                        nome='Agenda'
                        classAdd='homeCardClassHome'
                        col='col-xl-12'
                    >

                        <Table
                            campos={[
                                { nome: 'Data', tamanho: 120 },
                                { nome: 'Projeto' },
                                { nome: 'Proposta' },
                                { nome: 'Local' },
                            ]}
                            trs={() => {

                                return dataAgenda.map((item, index) => {

                                    return (
                                        <tr key={index}>
                                            <td>{item.data}</td>
                                            <td>{item.projeto}</td>
                                            <td>{item.proposta}</td>
                                            <td>{item.local}</td>
                                        </tr>
                                    )
                                })
                            }}
                        />

                    </Card>
                </div>


                <Card
                    nome='Aniversariantes do Mês'
                    classAdd='homeCardClassHome'
                    col='col-xl-4'
                >
                    <Table
                        campos={[
                            { nome: 'Nome' },
                            { nome: 'Dia', tamanho: 25 },
                        ]}
                        trs={() => {

                            return dataAniversariante.map((item, index) => {

                                return (
                                    <tr key={index}>
                                        <td>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <img src={item.avatar} alt="Avatar" width={20} height={20} style={{ borderRadius: 20 }} />
                                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
                                                    <span style={{ display: 'flex', flex: 1 }}>{item.nome}</span>
                                                    <small></small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{item.dia}</td>
                                    </tr>
                                )
                            })

                        }}
                    />
                </Card>


            </Container>
        </>
    )
}

export default Home