import React, { useRef } from 'react'
import { Form as UForm } from '@unform/web'
import ValidacaoYup from './ValidacaoYup'

function Form({ children, setRef, onSubmit, validacao, className, initialData }) {

    const formRef = useRef(null)

    async function onSubmitForm(data) {

        if (validacao) {
            try {
                
                /** Remove all previous errors */
                if (setRef)
                    setRef.current.setErrors({})
                else
                    formRef.current.setErrors({});

                const yupValidacao = ValidacaoYup.object().shape(validacao)                

                await yupValidacao.validate(data, {
                    abortEarly: false,
                });                            

                /** if insert remove id from array */
                let newData = null
                if (data.id !== '') {

                    newData = data
                }
                else {

                    delete data.id
                    newData = data
                }                

                /** Get Label selected from select async/create and add array submit data */
                document.querySelectorAll('[classID]').forEach(function (itemClassId) {

                    const attributeName = itemClassId.getAttribute('classID')
                    if (attributeName)
                        newData[attributeName] = itemClassId.lastChild.innerText
                })                

                /** Validation passed */
                if (setRef)
                    onSubmit(newData, setRef.current)
                else
                    onSubmit(newData, formRef.current)

            } catch (err) {

                const validationErrors = {};
                if (err instanceof ValidacaoYup.ValidationError) {

                    err.inner.forEach(error => {
                        validationErrors[error.path] = error.message;
                    });

                    if (setRef)
                        setRef.current.setErrors(validationErrors);
                    else
                        formRef.current.setErrors(validationErrors);
                }
            }
        }
        else {
            
            if (setRef)
                onSubmit(data, setRef.current)
            else
                onSubmit(data, formRef.current)
        }
    }

    return (
        <UForm ref={setRef ? setRef : formRef} onSubmit={onSubmitForm} className={className} initialData={initialData}>
            {children}
        </UForm>
    )
}

export default Form