import React, { useState } from 'react'
import './style.scss'

import { ContainerModal } from './../../components/Layout'
import { Button, Form, Input, Select, ValidacaoYup, SelectAsync } from './../../components/Form'

import { useLayout } from './../../context/Layout'
import api from './../../api'

function Simulacao() {

    const { setLoadFull } = useLayout()

    const [items, setItems] = useState([])

    const [dataMesAnoBusca, setMesAnoBusca] = useState({})

    async function onPesquisar(data) {
        setLoadFull(true)
        const resp = await api.post('simulacoes/pesquisa', { proposta: data.proposta, mesAno: data.mesAno  })
        setItems(resp.data.value)
        setLoadFull(false)
    }


    async function onBuscaDadosSelects(data) {

        if (data) {
            const mesAno = await api.post('simulacoes/comboMesAno', { proposta: data.value })
            // setMesAnoBusca(mesAno.data.value)
        }
    }

    function TopSearch() {

        return (
            <>

                <div className="fundoPesquisa">
                    <Form
                        onSubmit={onPesquisar}
                        className='col-xl-12 col-lg-12'
                    >
                        <div className="itemPesquisa">
                            <SelectAsync name='proposta' col='col-lg-6' titulo='Selecione uma proposta' router='simulacoes/comboProposta' />
                            <SelectAsync name='mesAno' col='col-lg-3' titulo='Mês / Ano' router='simulacoes/comboMesAno' />

                            {/* <SelectAsync
                                name='proposta'
                                col='col-lg-6'
                                titulo='Selecione uma proposta'
                                labelValue='nome_proposta'
                                router='simulacoes/comboProposta'
                                onChange={onBuscaDadosSelects}
                            />

                            <Select name='mesAno' col='col-lg-3' titulo='Mês / Ano' labelValue='mes_ano' options={dataMesAnoBusca} /> */}

                            <div col='col-lg-3'>
                                <Button name='FILTRAR' />
                            </div>

                        </div>
                    </Form>
                </div>

            </>
        )
    }

    return (
        <>
            <ContainerModal
                route='simulacoes'
                title='Simulações'
                showButtonExcluir={false}
                showButtonEditar={false}
                showButtonNovo={false}
                topSearch={TopSearch}
                dataExterno={items}
                campos={[
                    { nome: 'Proposta' },
                    { nome: 'Periodo' },
                    { nome: 'Dias' },
                    { nome: 'Valor' },
                    { nome: 'Pago' },
                    { nome: 'Data Pagto' },
                    { nome: 'Saldo' }
                ]}
            >

            </ContainerModal>
        </>
    )
}

export default Simulacao