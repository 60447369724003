import React, { useEffect } from 'react'
import './style.scss'

function Tab({ tabs }) {

    useEffect(() => {        

        const tabsNavs = document.querySelectorAll('.tab-nav')        

        for (const tab of tabsNavs) {

            tab.addEventListener('click', function (event) {                

                const navlink = document.querySelector('.tab.active')
                const tabpane = document.querySelector('.tab-pane.active')

                const tabsActives = document.querySelectorAll('.tab.active')
                for (const t of tabsActives)
                    t.classList.remove('active')

                if (navlink && navlink.classList.contains('active'))
                    navlink.classList.remove('active')

                if (tabpane && tabpane.classList.contains('active'))
                    tabpane.classList.remove('active')

                event.target.classList.add('active')

                const tabid = event.target.id.replace('tab-nav-', '')
                const tabitem = document.querySelector(`#tab-item-${tabid}`)

                if (tabitem)
                    tabitem.classList.add('active')

            })
        }

    }, [])

    return (
        <div className="block">
            <ul className="nav nav-tabs nav-tabs-alt js-tabs-enabled" data-toggle="tabs" role="tablist">
                {
                    tabs.map((t, index) => {
                        return (
                            <li key={index} className="nav-item tab-nav" hidden={t.hide}>
                                {
                                    t.icon && t.icon
                                }
                                <a id={`tab-nav-${index}`} className={`nav-link tab ${index === 0 && 'active'}`} href="#!">{t.nome}</a>
                            </li>
                        )
                    })
                }
            </ul>
            <div className="block-content tab-content">
                {
                    tabs.map((t, index) => {                        

                        return (
                            <div key={index} className={`tab-pane ${index === 0 && 'active'}`} id={`tab-item-${index}`} role="tabpanel" hidden={t.hide}>
                                {t.body}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Tab