import React from 'react'

import Nav from './Nav'
import Header from './Header'

function Container({
    classAdd = 'homeClass',
    routerSearch = '',
    setDataGrid,
    onBuscaDadosGrid,
    children,
    showTopNavigation,
    topSearch,
    showSearch
}) {

    return (
        <>
            <Nav />
            <div className="main-content" id="panel">

                <Header
                    classAdd={showTopNavigation ? '' : classAdd}
                    routerSearch={routerSearch}
                    setDataGrid={setDataGrid}
                    onBuscaDadosGrid={onBuscaDadosGrid}
                    showTopNavigation={showTopNavigation}
                    TopSearch={topSearch}     
                    showSearch={showSearch}               
                />

                <div className="container-fluid mt--6">
                    <div className="row">
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Container