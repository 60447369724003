import React, { useEffect, useRef } from 'react';
import './style.scss'

import { useField } from '@unform/core';
import ReactSelect, { OptionTypeBase, Props as SelectProps, } from 'react-select';

function Select({ name, titulo, col, ...props }) {
    const selectRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            path: 'state.value',
            getValue: (ref) => {
                if (props.isMulti) {
                    if (!ref.state.value) {
                        return [];
                    }
                    return ref.state.value.map((option) => option.value);
                } else {
                    if (!ref.state.value) {
                        return '';
                    }
                    return ref.state.value.value;
                }
            },
            setValue: (ref, value) => {
                ref.select.setValue(value);
            },
        });
    }, [fieldName, registerField, props.isMulti]);

    return (
        <div className={`cInputD selectD ${col}`}>
            <span>{titulo}</span>
            <div className="input-group-merge input-group-alternative">
                <ReactSelect
                    id={name}                    
                    defaultValue={defaultValue}
                    ref={selectRef}
                    placeholder='Selecionar'
                    classNamePrefix="react-select"
                    noOptionsMessage={() => 'Sem dados'}
                    {...props}
                />
            </div>
            {error && <span className="error-validacao">{error}</span>}
        </div>
    );
}

export default Select