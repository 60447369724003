import React from 'react'

function SemRegistro() {

    return (
        <div className='noFile'>
            <img src='assets/nofile/nofile.svg' />
            <span>SEM REGISTRO</span>
        </div>
    )
}

export default SemRegistro