import React from 'react'
import './style.scss'

import { ContainerModal } from './../../../components/Layout'
import { Input, ValidacaoYup } from './../../../components/Form'

function Certificacao() {

    return (
        <ContainerModal
            route='certificacoes'
            title='Certificações'
            showButtonPagination={false}
            validacao={{
                Desc_Cert: ValidacaoYup.string().min(3).required(),
                InstEns_Cert: ValidacaoYup.string().min(3).required(),
            }}
            showTopNavigation={true}
            campos={[
                { nome: 'Descrição' },
                { nome: 'Instituição de Ensino' },
                { nome: 'Data' },
            ]}
        > 

            <Input name='Desc_Cert' col='col-lg-12' titulo='Certificação' placeholder='Digite a certificação' />
            <Input name='InstEns_Cert' col='col-lg-12' titulo='Instituto de Ensino' placeholder='Digite o instituto de ensino' />

            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Input name='Mes_Cert' col='col-lg-3' titulo='Mês' placeholder='0' />
                <Input name='Ano_Cert' col='col-lg-3' titulo='Ano' placeholder='0' />
            </div>

        </ContainerModal>
    )
}

export default Certificacao