import React, { useState, useEffect } from 'react'
import './style.scss'

import { NavLink } from 'react-router-dom'

import { BsHouseDoorFill, BsFillCalendarFill, BsFillBriefcaseFill, BsFillBookmarksFill, BsPersonSquare, BsFillPeopleFill, BsListCheck, BsCloudUpload } from 'react-icons/bs'
import {AiOutlineLogin} from 'react-icons/ai'
import { useAuth } from './../../context/Auth'

function Menu() {

    const { getUser } = useAuth()

    return (
        <nav className="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white" id="sidenav-main" >
            <div className="scrollbar-inner">

                <div className="sidenav-header  align-items-center">
                    <a className="navbar-brand" href="#!">
                        <img src="../logo.png" style={{ width: '100%' }} className="navbar-brand-img" alt="..." />
                    </a>
                    <div className='sair__on' onClick={() => {                        
                        document.body.classList.value = 'g-sidenav-hidden'
                    }}>
                        <span>Fechar</span>
                        <AiOutlineLogin />
                    </div>
                </div>
                <div className="navbar-inner">

                    <div className="collapse navbar-collapse" id="sidenav-collapse-main">

                        <ul className="navbar-nav">

                            {
                                getUser().perfil_consultor === 'Desenvolvedor' ?

                                    <li className="nav-item">
                                        <NavLink className="nav-link" activeClassName='active' to='/arquivo'>
                                            <BsCloudUpload />
                                            <span className="nav-link-text">Arquivos</span>
                                        </NavLink>
                                    </li>

                                    : <>


                                        <li className="nav-item">
                                            <NavLink className="nav-link" activeClassName='active' to='/home'>
                                                <BsHouseDoorFill />
                                                <span className="nav-link-text">Home</span>
                                            </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink className="nav-link" activeClassName='active' to='/agenda'>
                                                <BsFillCalendarFill />
                                                <span className="nav-link-text">Agenda</span>
                                            </NavLink>
                                        </li>

                                        < li className="nav-item">
                                            <NavLink className="nav-link" activeClassName='active' to='/catalogo'>
                                                <BsFillBookmarksFill />
                                                <span className="nav-link-text">Catálogo</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" activeClassName='active' to='/pagamento'>
                                                <BsFillBriefcaseFill />
                                                <span className="nav-link-text">Pagamentos</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" activeClassName='active' to='/projeto'>
                                                <BsListCheck />
                                                <span className="nav-link-text">Minha Programação</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" activeClassName='active' to='/projetoequipe'>
                                                <BsFillPeopleFill />
                                                <span className="nav-link-text">Programação Equipe</span>
                                            </NavLink>
                                        </li>
                                        {/* <li className="nav-item">
                                            <NavLink className="nav-link" activeClassName='active' to='/contato'>
                                                <BsFillPersonLinesFill />
                                                <span className="nav-link-text">Contatos</span>
                                            </NavLink>
                                        </li> */}
                                        <li className="nav-item">
                                            <NavLink className="nav-link" activeClassName='active' to='/dadospessoais'>
                                                <BsPersonSquare />
                                                <span className="nav-link-text">Info. Pessoais</span>
                                            </NavLink>
                                        </li>
                                    </>
                            }

                        </ul>
                    </div>
                </div>            
            </div>            
        </nav >
    )
}

export default Menu