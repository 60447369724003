import React from 'react'
import './style.scss'

import { useLayout } from './../../../context/Layout'
import ReactLoading from 'react-loading'

import Colors from './../../../styles/Colors'

function Load() {
    const { loadFull } = useLayout()

    return (
        <>
            {
                loadFull ?
                    <div className="loadfull">
                        <ReactLoading type='cubes' color={Colors.primary} height={50} width={50} />
                    </div> : undefined
            }
        </>
    )
}
export default Load