import React, { useEffect, useState } from 'react'

import { useAuth } from './../../context/Auth'
import { useHistory, Link } from 'react-router-dom'

import api from './../../api'
import { useLayout } from './../../context/Layout'

import TopNavigation from './TopNavigation'

import { RiLockPasswordLine, RiUserLocationLine } from 'react-icons/ri'

function Header({
    classAdd = '',
    routerSearch = '',
    setDataGrid,
    onBuscaDadosGrid,
    showTopNavigation,
    TopSearch,
    showSearch = true
}) {

    const { removeUser, getNivel, getUser } = useAuth()
    const history = useHistory()
    const { setLoadFull } = useLayout()
    const [showPerfilOptions, setShowPerfilOptions] = useState(false)
    const [nome, setNome] = useState('')
    const [sobrenome, setSobrenome] = useState('')


    useEffect(() => {

        let search = document.querySelector('#search-input')
        if (search)
            document.querySelector('#search-input').addEventListener('keyup', async function (event) {

                if (event.keyCode === 13 && routerSearch !== '') {
                    event.preventDefault();

                    if (document.querySelector('#search-input').value === '') {
                        onBuscaDadosGrid()
                        return
                    }

                    setLoadFull(true)
                    const resp = await api.post(routerSearch, {
                        pesquisa: document.querySelector('#search-input').value
                    })

                    setDataGrid(resp.data.value)
                    setLoadFull(false)
                }
            })

        document.body.addEventListener('click', () => {

            // console.log(document.body.classList.contains('g-sidenav-pinned'))

            // if (document.body.classList.contains('g-sidenav-pinned')) {
            //     document.body.classList.remove('g-sidenav-pinned')
            //     document.body.classList.add('g-sidenav-hidden')
            //     document.body.classList.add('g-sidenav-hidden')
            // }
        })

        // let nomePrincipal = getUser().nome_consultor.split(" ")[0]

        var str = getUser().nome_consultor
        var index = str.indexOf(' ');
        var [first, second] = [str.slice(0, index), str.slice(index + 1)];

        setNome(first)
        setSobrenome(second)

    }, [])

    function onSair() {

        removeUser()
        history.push("/")
    }

    function onPerfil() {

        setShowPerfilOptions(!showPerfilOptions)
    }

    return (
        <>
            <nav className={`navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom diego ${classAdd}`}>
                <div className="container-fluid">
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">

                        <div className="navbar-search navbar-search-light form-inline mr-sm-3" id="navbar-search-main">
                            {
                                showSearch ?
                                    TopSearch ? <TopSearch /> :
                                        <div className="form-group mb-0">
                                            <div className="input-group input-group-alternative input-group-merge">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="fas fa-search"></i></span>
                                                </div>
                                                <input id='search-input' className="form-control" placeholder="Pesquisar" type="text" />
                                            </div>
                                        </div> : undefined

                            }
                            <button type="button" className="close" data-action="search-close" data-target="#navbar-search-main"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <ul className="navbar-nav align-items-center  ml-md-auto ">
                            <li className="nav-item d-xl-none">

                                <div className="pr-3 sidenav-toggler sidenav-toggler-dark" data-action="sidenav-pin"
                                    data-target="#sidenav-main">
                                    <div className="sidenav-toggler-inner">
                                        <i className="sidenav-toggler-line"></i>
                                        <i className="sidenav-toggler-line"></i>
                                        <i className="sidenav-toggler-line"></i>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item d-sm-none">
                                <a className="nav-link" href="#" data-action="search-show" data-target="#navbar-search-main">
                                    <i className="ni ni-zoom-split-in"></i>
                                </a>
                            </li>
                        </ul>
                        <ul class="navbar-nav align-items-center  ml-auto ml-md-0 " style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                            <li class="nav-item dropdown">
                                <a class="nav-link pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <div class="media align-items-center">
                                        <div class="media-body  d-none d-lg-block">
                                            <span class="mb-0 text-sm  font-weight-bold" style={{ marginRight: 10, display: 'block', lineHeight: 0 }}>
                                                {nome}
                                            </span>
                                            <span class="mb-0 font-weight-bold" style={{ marginRight: 10, fontSize: 8, lineHeight: 0 }}>
                                                {sobrenome}
                                            </span>
                                        </div>
                                        <span class="avatar avatar-sm rounded-circle">
                                            {Number(getNivel()) !== 0 && <img src={getUser().avatar} alt="" className='img-avatar' onClick={onPerfil} />}
                                        </span>
                                    </div>
                                </a>
                                <div class="dropdown-menu  dropdown-menu-right ">
                                    <div class="dropdown-header noti-title">
                                        <h6 class="text-overflow m-0">Bem vindo!</h6>
                                    </div>
                                    <Link to='/dadospessoais' class="dropdown-item">
                                        <RiUserLocationLine />
                                        <span>Alterar minhas informações</span>
                                    </Link>
                                    <Link to='/minhasenha' class="dropdown-item">
                                        <RiLockPasswordLine />
                                        <span>Alterar minha senha</span>
                                    </Link>
                                    <div class="dropdown-divider"></div>
                                    <a href="#!" onClick={onSair} class="dropdown-item">
                                        <i class="ni ni-user-run"></i>
                                        <span>Sair</span>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            {showTopNavigation ? <TopNavigation /> : undefined}
        </>
    )
}

export default Header