import React, { useState, useEffect } from 'react'

import { Container, Card, Modal, Tab, mensagemSucesso, mensagemErro } from './../../components/Layout'
import { FiPlusCircle } from 'react-icons/fi'

import { SemRegistro, MoreRegistro, Deletar, Editar, ButtonGenerico, Table } from './../../components/Page'

import { useLayout } from './../../context/Layout'
import api from './../../api'

function ContainerModal({
    children,
    route = '',
    title = '',
    campos = [],
    tableButtons = [],
    buttons = [],
    validacao = {},
    showButtonSave = true,
    showButtonNovo = true,
    showButtonExcluir = true,
    showButtonEditar = true,
    nomeButtonSave = 'Salvar',
    onNovoBefore, // return setDataEdit
    onSaveBefore, // return data
    onEditarAfter, // return data, setData, setLoad, setModal
    editarIcon, // Icon react-icons
    editarTooltip, // Texto do tooltip
    reload, // atualiza page
    useFormData = false, // True para envio de imagens
    showTopNavigation, //Mostrar Menu Superior das Informacoes Pessoais
    topSearch, //Mostrar Menu Superior de Pesquisa
    showButtonPagination = true,
    dataExterno = [],
    showSearch = true, // Se true mostra a barra de pesquisa no Header,
    tableColumnColor = false
}) {

    const { setLoadFull } = useLayout()

    const [page, setPage] = useState(1)
    const [load, setLoad] = useState(false)
    const [modal, setModal] = useState(false)
    const [data, setData] = useState([])
    const [dataEdit, setDataEdit] = useState({})

    const [showLoadMore, setShowLoadMore] = useState(true)

    useEffect(() => {
        if (dataExterno.length > 0)
            setData(dataExterno)
    }, [dataExterno])

    useEffect(() => {
        if ((route) && (dataExterno.length === 0))
            onBuscaDados()
    }, [page, reload])

    function ButtonNovo() {

        function onNovo() {

            setDataEdit({})

            if (onNovoBefore)
                onNovoBefore(setDataEdit)

            setModal(true)
        }

        return (
            <div onClick={onNovo} data-rh={`Novo(a) ${title}`}>
                <FiPlusCircle />
            </div>
        )
    }

    async function onBuscaDados() {

        setLoadFull(true)
        const resp = await api.post(`${route}/dados`, { page })

        if (resp.data.type === 2) {

            setLoad(false)
            mensagemErro(`${resp.data.value}. - #serv`)

            console.clear()
            console.log(resp.data.value)

            return
        }

        if (resp.status === 200) {

            console.log(resp.data)

            if (page === 1) {
                setData(resp.data.value ? resp.data.value : [])
                setShowLoadMore(resp.data.value && resp.data.value.length >= 10)
            }
            else {
                setData([...data, ...resp.data.value])
                setShowLoadMore(resp.data.value.length !== 0 || resp.data.value.length > 10)
            }
        }
        setLoadFull(false)
    }

    async function onSave(data) {

        if (!route) return

        if (onSaveBefore) {
            data = onSaveBefore(data)

            if (data === true) return
        }

        setLoad(true)
        let resp

        // USE FORM DATA - PARA ENVIO DE IMAGENS/FILES
        if (useFormData) {

            var form_data = new FormData();
            for (var key in data) {
                form_data.append(key, data[key]);
            }

            if (data?.id)
                resp = await api.put(`${route}/${data.id}`, form_data, { headers: { 'Content-Type': 'multipart/form-data' } })
            else
                resp = await api.post(route, form_data, { headers: { 'Content-Type': 'multipart/form-data' } })
        }
        // ENVIO TRADICIONAL
        else {

            if (data?.id)
                resp = await api.put(`${route}/${data.id}`, data)
            else
                resp = await api.post(route, data)
        }

        if (resp.data.type === 2) {

            setLoad(false)
            mensagemErro(`${resp.data.value}. - #serv`)

            console.clear()
            console.log(resp.data.value)

            return
        }

        setLoad(false)
        setModal(false)

        onBuscaDados()
        mensagemSucesso(`${title} ${data?.id ? 'atualizado' : 'criado'} com sucesso!`)
    }

    async function onEditar(id) {

        setLoadFull(true)

        const resp = await api.get(`${route}/${id}`)

        if (resp.data.type === 2) {

            setLoad(false)
            mensagemErro(`${resp.data.value}. - #serv`)

            // console.clear()
            // console.log(resp.data.value)

            return
        }

        if (onEditarAfter) {

            onEditarAfter(resp.data.value, setDataEdit, setLoadFull, setModal)
        }
        else {

            setDataEdit(resp.data.value)
            setLoadFull(false)
            setModal(true)
        }
    }

    return (
        <>
            <Container
                classAdd='homeClass'
                routerSearch={`${route}/pesquisa`}
                setDataGrid={setData}
                onBuscaDadosGrid={onBuscaDados}
                showTopNavigation={showTopNavigation}
                topSearch={topSearch}
                showSearch={showSearch}
            >
                <Card
                    nome={title}
                    buttons={showButtonNovo ? [...buttons, <ButtonNovo />] : [...buttons]}
                >

                    {
                        data.length === 0 ? <SemRegistro /> :
                            <>
                                <Table
                                    campos={[...campos, '']}

                                    trs={() => {
                                        const tabela = data.map((item) => {

                                            // console.log('-------------item')
                                            // console.log(item)

                                            return (
                                                <tr key={item.id}>
                                                    {
                                                        item.dados.map((dado, index) => {


                                                            const comparar = tableColumnColor ? index % 2 == 0 : false

                                                            if (String(dado).indexOf('http') > -1)
                                                                return <td key={index} style={{ width: 40, background: comparar ? '#f6f9fc' : '#fff' }} ><img src={dado} alt="avatar" style={{ width: 40, height: 40, borderRadius: 20 }} /></td>
                                                            else
                                                                return <td key={index} style={{ background: comparar ? '#f6f9fc' : '#fff' }} >{dado}</td>
                                                        })
                                                    }
                                                    <td className="text-right button-delete-editar">

                                                        {
                                                            tableButtons && tableButtons.map((ButtonItem, index) => <ButtonItem key={index} item={item} />)
                                                        }

                                                        {showButtonEditar && <Editar item={item} NewIcon={editarIcon} newTooltip={editarTooltip} onEditar={() => onEditar(item.id)} />}
                                                        {showButtonExcluir && <Deletar item={item} onBuscaDados={onBuscaDados} route={route} />}
                                                    </td>
                                                </tr>
                                            )
                                        })

                                        return tabela
                                    }}
                                />
                                {showButtonPagination && <MoreRegistro page={page} setPage={setPage} showLoadMore={showLoadMore} />}
                            </>
                    }

                </Card>
            </Container>

            {
                modal &&
                <Modal
                    title={`Novo(a) ${title}`}
                    showModal={modal}
                    onClose={() => setModal(false)}
                    onSave={onSave}
                    nomeButtonSave={nomeButtonSave}
                    load={load}
                    data={dataEdit}
                    validacao={validacao}
                    showButtonSave={showButtonSave}>

                    {children}

                </Modal>
            }

        </>
    )
}

export default ContainerModal