import React, { useState, useRef } from 'react'
import './style.scss'

import { Container, Card, mensagemInfo, mensagemSucesso, mensagemErro } from './../../components/Layout'
import { Form, Pass, ButtonSave, Input, ValidacaoYup } from './../../components/Form'

import { useLayout } from './../../context/Layout'
import api from './../../api'

function MinhaSenha() {

    const { setLoadFull } = useLayout()
    const formRef = useRef(null)

    async function onSave(data) {

        setLoadFull(true)

        const { senha, novasenha, senhaRepete } = data

        if (senha.length === 0) {
            mensagemInfo('Preencha o campo da Senha anterior')
            setLoadFull(false)
            return
        }

        if ((novasenha && !senhaRepete) || (!novasenha && senhaRepete)) {
            mensagemInfo('Preencha os dois campos de Senha Novas')
            setLoadFull(false)
            return
        }

        if (novasenha && senhaRepete && (novasenha !== senhaRepete)) {
            mensagemInfo('As Senhas devem ser iguais')
            setLoadFull(false)
            return
        }

        if ((novasenha && senhaRepete) && (novasenha.length < 6)) {
            mensagemInfo('A Senha deve conter mais de 6 caracteres')
            setLoadFull(false)
            return
        }

        const resp = await api.post('users/alterarsenha', {
            senhaOld: senha,
            senhaNew: novasenha,
            senhaConfirm: senhaRepete
        })

        if (resp.status === 200) {

            if (resp.data.type === 1) {
                mensagemInfo(resp.data.message)
                setLoadFull(false)
                return
            }

            mensagemSucesso('Dados atualizados com sucesso!')
        } else {
            mensagemErro('Algo deu errado, por favor entre em contato! #serv')
        }


        formRef.current.reset()
        setLoadFull(false)
    }


    return (
        <Container>
            <Card nome='Alterar minha senha'>

                <div className="col-lg-12" style={{}}>
                    Novas senhas requerem um minímo de 8 caracteres e devem conter ao menos uma letra<br />
                    um número e um carácter especial com exceção de (&=?%)
                </div>

                <Form
                    onSubmit={onSave}
                    setRef={formRef}
                >
                    <Pass name='senha' col='col-lg-5' titulo='Senha' placeholder='Digite sua senha atual' />
                    <Pass name='novasenha' col='col-lg-5' titulo='Nova Senha' placeholder='Digite sua nova senha' />
                    <Pass name='senhaRepete' col='col-lg-5' titulo='Confirmar Senha' placeholder='Confirme sua nova senha' />

                    <div className='col-lg-6 m-top-16'>
                        <ButtonSave name='ATUALIZAR' />
                    </div>
                </Form>
            </Card>
        </Container>
    )
}

export default MinhaSenha