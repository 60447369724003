import React from 'react';
import './style.scss';

function Manutencao() {
return (
    <div className="maintenance-container">
        <div className="content">
            <img src="/logo.png" alt="Logo" style={{ width: '30%' }} className="pb-5" />
            <h1 className="title-tag mb-4">Página em construção!</h1>
            <p className="text-large mb-5">Estamos trabalhando para trazer novidades e melhorar sua experiência!</p>
            <p className="text">Enquanto isso, você pode acessar a Intranet e todas as suas funcionalidades através do link abaixo:</p>
            <a href="https://intranet.institutoaquila.com" rel="noreferrer" className="link">intranet.institutoaquila.com</a>
            <p className="text mt-5 mb-3">Agradecemos sua compreensão e estamos à disposição para qualquer dúvida!</p>
            <p className="text-medium">Equipe Marketing</p>
            <p className="text">marketing@aquila.com.br</p>
        </div>
    </div>
);
}

export default Manutencao;