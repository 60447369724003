// src/api/index.js
import axios from 'axios';
import { authKey } from './../context/Auth';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_SERV}`,
});

api.interceptors.request.use(async function (config) {
  const usuario = JSON.parse(localStorage.getItem(authKey));
  let token;

  if (usuario) token = usuario.token;

  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

api.interceptors.response.use(
  response => response,
  async error => {
    const access_token = localStorage.getItem(authKey);

    if (error.response.status === 401 || !access_token) {
      localStorage.removeItem(authKey);

      api.defaults.headers.Authorization = undefined;
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export default api;