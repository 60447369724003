// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/bg-construcao.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".maintenance-container {\n  position: relative;\n  width: 100vw;\n  height: 100vh;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #fff;\n}\n.maintenance-container::before {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 255, 255, 0.7);\n  -webkit-backdrop-filter: blur(7px);\n          backdrop-filter: blur(7px);\n}\n.maintenance-container .content {\n  position: relative;\n  z-index: 1;\n  text-align: center;\n}\n.maintenance-container .content img {\n  display: block;\n  margin: 0 auto;\n}\n.maintenance-container .title-tag {\n  background-color: #09324C;\n  color: white !important;\n  padding: 15px 40px;\n  border-radius: 16px;\n  display: inline-block;\n  font-family: Verdana, Geneva, Tahoma, sans-serif;\n  font-weight: 600;\n}\n.maintenance-container .text-large {\n  font-size: 20px;\n  font-weight: 700;\n  color: #000000;\n  font-family: Verdana, Geneva, Tahoma, sans-serif;\n}\n.maintenance-container .text-medium {\n  font-size: 16px;\n  font-weight: 600;\n  color: #000000;\n  font-family: Verdana, Geneva, Tahoma, sans-serif;\n  margin: 0;\n}\n.maintenance-container .text {\n  color: #000000;\n  font-family: Verdana, Geneva, Tahoma, sans-serif;\n  font-weight: 100;\n  font-size: 14px;\n  margin: 0;\n}\n.maintenance-container .link {\n  color: #09324C;\n  font-family: Verdana, Geneva, Tahoma, sans-serif;\n  font-weight: 600;\n  font-size: 18px;\n}", ""]);
// Exports
module.exports = exports;
