import React, { useEffect, useRef } from 'react';
import './style.scss'

import { useField } from '@unform/core';

function Textarea({ name, titulo, col, groupClass, hidden, ...props }) {
    const inputRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <div className={`cInputD ${col}`} style={{ display: `${hidden ? 'none' : ''}` }}>
            <span>{titulo}</span>
            <div className={`input-group input-group-merge input-group-alternative ${groupClass}`}>
                <textarea id={name} autoComplete='no' ref={inputRef} defaultValue={defaultValue} {...props} />
            </div>
            {error && <span className="error-validacao">{error}</span>}
        </div>
    );
}

export default Textarea