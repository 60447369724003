import React from 'react'
import { FiPlusCircle } from 'react-icons/fi'

function MoreRegistro({ page, setPage, showLoadMore }) {

    function onLoadMore() {
        setPage(page + 1)
    }

    return (
        <>
            {
                showLoadMore ?
                    <div className='loadMore' onClick={onLoadMore}>
                        <FiPlusCircle />
                        <span>Carregar mais itens</span>
                    </div> : undefined
            }
        </>
    )
}

export default MoreRegistro