import React from 'react'
import './style.scss'

import { ContainerModal } from './../../../components/Layout'
import { Input, Select, ValidacaoYup } from './../../../components/Form'

function Curso() {

    return (
        <ContainerModal
            route='cursos'
            title='Cursos Externos'
            showButtonPagination={false}
            validacao={{
                desc_cursoext: ValidacaoYup.string().min(3).required(),
                desc_instensinoext: ValidacaoYup.string().min(3).required(),
            }}
            showTopNavigation={true}
            campos={[
                { nome: 'Descrição' },
                { nome: 'Instituição de Ensino' },
                { nome: 'Data' },
            ]}
        >

            <Input name='desc_cursoext' col='col-lg-12' titulo='Descrição' placeholder='Digite a descrição' />
            <Input name='desc_instensinoext' col='col-lg-12' titulo='Instituição de ensino' placeholder='Digite a instituição de ensino' />

            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Input name='mes_cursoext' col='col-lg-4' titulo='Mês' placeholder='0' />
                <Input name='ano_cursoext' col='col-lg-4' titulo='Ano' placeholder='0' />
                <Input name='CHor_CursoExt' col='col-lg-4' titulo='Carga Horária' placeholder='0' />
            </div>

            <Select name='Tipo_CursoExt' col='col-lg-12' titulo='Tipo' options={[
                { value: 'P', label: 'Presencial' },
                { value: 'V', label: 'Virtual' },
            ]} />

        </ContainerModal>
    )
}

export default Curso