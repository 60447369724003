import React from 'react'
import './style.scss'

import { ContainerModal } from './../../components/Layout'
import { ValidacaoYup } from './../../components/Form'

function Catalogo() {

    return (
        <>
            <ContainerModal
                route='consultores'
                title='Catalogos'
                showButtonExcluir={false}
                showButtonEditar={false}
                showButtonNovo={false}
                campos={[
                    { nome: 'Nome' },
                    { nome: 'Email' },
                    { nome: 'Celular' },
                    { nome: 'Linkedin' }
                ]}
                validacao={{
                    nome: ValidacaoYup.string().required(),
                    status: ValidacaoYup.string().min(1).required()
                }}
                onNovoBefore={setData => {
                    setData({
                        status: { value: 'A', label: 'Ativo' }
                    })
                }}
            >

            </ContainerModal>
        </>
    )
}

export default Catalogo