import React, { createContext, useContext } from 'react'

const AuthContext = createContext()
const authKey = '@#aquila'

function AuthProvider({ children }) {

    function setUser(dados) {
        localStorage.setItem(authKey, JSON.stringify(dados))
    }

    function getUser() {
        const usuario = JSON.parse(localStorage.getItem(authKey))
        return usuario
    }

    function removeUser() {
        localStorage.removeItem(authKey)
    }

    function getNivel() {

        const usuario = JSON.parse(localStorage.getItem(authKey))        
        return usuario.token
    }

    function getToken() {
        const usuario = JSON.parse(localStorage.getItem(authKey))
        const token = usuario.token
        return Number(token)
    }

    return (
        <AuthContext.Provider value={{ setUser, getUser, removeUser, getToken, getNivel }}>
            {children}
        </AuthContext.Provider>
    )
}

function useAuth() {
    const context = useContext(AuthContext)
    return context
}

export { useAuth, AuthProvider, authKey }