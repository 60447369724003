import React from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'react-alert-confirm/dist/index.css'

import ReactHintFactory from 'react-hint'
import 'react-hint/css/index.css'

import Routes from './Routes'
import Store from './context/Store'

const ReactHint = ReactHintFactory(React)
function App() {
    return (
        <Store>
            <ReactHint autoPosition events position='left'   /> 
            <Routes />
            <ToastContainer />
        </Store>
    );
}

export default App;
