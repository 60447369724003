import React, { useState } from 'react'
import './style.scss'

import { useHistory } from 'react-router-dom'

import { Input, Form, ButtonSave, ValidacaoYup } from './../../components/Form'
import api from './../../api'

function EsqueciSenha() {

    const history = useHistory()

    const [load, setLoad] = useState(false)
    const [validacao, setValidacao] = useState('')

    const [showNovaSenha, setShowNovaSenha] = useState(false)
    const [email, setEmail] = useState('')


    function EsqueciSenha() {

        async function onEsqueciSenha(data) {

            setValidacao('')
            setLoad(true)

            const resp = await api.post('login/esquecisenha', data)

            if (resp.data.type === 2) {
                setValidacao(resp.data.value)
                setLoad(false)
                return
            }

            setEmail(data.email_esqueci_senha)
            setLoad(false)
            setShowNovaSenha(true)
        }

        return (
            <Form onSubmit={onEsqueciSenha} validacao={{ email_esqueci_senha: ValidacaoYup.string().email().required() }}>

                <Input name='email_esqueci_senha' titulo='Digite seu E-mail' placeholder='Digite seu e-mail' className='form-control' />

                <div className="text-center">
                    {validacao.length > 0 && <div style={{ marginTop: 20 }} className="validaRetorno">{validacao}</div>}
                </div>

                <div className='famdim-button-login'>
                    <ButtonSave
                        load={load}
                        disabled={load}
                        name='Esqueci a senha'
                    />
                </div>

                <div className='btn btn-primary buttonEnviar famdim-button-login-div-out' onClick={() => history.push('/')}>
                    <span>Voltar</span>
                </div>
            </Form>
        )
    }

    function NovaSenha() {

        async function onNovaSenha(data) {

            const { senha, repeteSenha, codigo } = data

            if (senha.length > 0 && repeteSenha.length > 0 && (senha === repeteSenha)) {

                setLoad(true)
                const resp = await api.post('login/novasenha', {
                    senha,
                    codigo,
                    email
                })

                if (resp.data.value === 2) {
                    setValidacao(resp.data.value)
                    setLoad(false)
                    return
                }

                setLoad(false)
                history.push('/')
            }
            else {
                setValidacao('As senhas estão diferentes')
            }
        }

        return (
            <>
                <div className='div-info-nova-senha'>
                    <span>Enviamos um código para seu e-mail,</span>
                    <span>digite sua nova senha e informe </span>
                    <span>o código para atualiza-las</span>
                </div>
                <Form onSubmit={onNovaSenha} validacao={{
                    senha: ValidacaoYup.string().required(),
                    repeteSenha: ValidacaoYup.string().required(),
                    codigo: ValidacaoYup.string().required()
                }}>

                    <Input type='password' name='senha' titulo='Digite sua Senha' placeholder='Digite sua Senha' className='form-control' />
                    <Input type='password' name='repeteSenha' titulo='Repita sua senha' placeholder='Repita sua senha' className='form-control' />
                    <Input name='codigo' titulo='Digite o código' placeholder='Digite o código' className='form-control' />

                    <div className="text-center">
                        {validacao.length > 0 && <div style={{ marginTop: 20 }} className="validaRetorno">{validacao}</div>}
                    </div>

                    <div className='famdim-button-login'>
                        <ButtonSave load={load} disabled={load} name='Alterar senha' />
                    </div>
                </Form>
            </>
        )
    }

    return (
        <>
            {
                <div className="main-content login-fundo" style={{ minHeight: '100vh' }}>
                    <div className="container pb-5" style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-7" style={{minWidth: 380}} >
                                <div className="card bg-secondary border-0 mb-0 famdim-login">
                                    <div className="card-body px-lg-5 py-lg-5" style={{ backgroundColor: '#f8f9fe' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 30 }}>
                                            <img style={{ width: '80%' }} src='logo.png' />
                                        </div>

                                        {showNovaSenha ? <NovaSenha /> : <EsqueciSenha />}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default EsqueciSenha