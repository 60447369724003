import React, { useEffect, useRef } from 'react';
import './style.scss'

import { useField } from '@unform/core';

function Checkbox({ name, titulo, col, ...props }) {    

    const inputRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {

        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'checked',
        });
    }, [fieldName, registerField]);

    return (
        <div className={col}>
            <div className="custom-control custom-checkbox">
                <input id={name} ref={inputRef} defaultValue={defaultValue} type="checkbox" className="custom-control-input" {...props} />
                <label className="custom-control-label" htmlFor={name}>{titulo}</label>
                {error && <span className="error-validacao">{error}</span>}
            </div>
        </div>
    );
}

export default Checkbox