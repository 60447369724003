import React from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid'
import ptBR from "@fullcalendar/core/locales/pt-br";
import './style.scss';
import { Container } from './../../components/Layout'


export default class App extends React.Component {
    calendarRef = React.createRef();

    render() {
        const events = [
            {
                id: 1,
                title: 'Alfa Transporte Eireli' + '\n' + 'Local: Caçador' + '\n' + 'Proposta: 0084/20PAN',
                start: '2020-12-30',
                classNames: ['andamento'],
                allDay: true
            }, {
                id: 2,
                title: 'Leonardo',
                start: '2020-12-25',
                classNames: ['atrasado'],
                allDay: true
            }, {
                id: 3,
                title: 'Glenio',
                start: '2020-12-06',
                classNames: ['andamento'],
                allDay: true
            }];

        return (
            <>
                <Container
                    classAdd='semHomeClass'
                >
                    <div className="App">
                        <FullCalendar defaultView="dayGridMonth"
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            dateClick={this.handleDateClick}
                            ref={this.calendarRef}
                            header={{
                                left: 'today',
                                center: 'previousMonth title nextMonth',
                                right: 'dayGridMonth'
                            }}
                            buttonText={{
                                list: 'Dia',
                            }}
                            customButtons={{
                                previousMonth: {
                                    click: () => {
                                        this.handlePreviousMonth();
                                    }
                                },
                                nextMonth: {
                                    click: () => {
                                        this.handleNextMonth();
                                    }
                                }
                            }}
                            buttonIcons={{
                                previousMonth: 'chevron-left',
                                nextMonth: 'chevron-right'
                            }}
                            initialView='dayGridMonth'
                            editable={true}
                            selectable={true}
                            selectMirror={true}
                            dayMaxEvents={true}
                            weekends={true}
                            fullscreen={false}
                            events={events}
                            // select={this.handleDateSelect}
                            eventContent={renderEventContent} // custom render function
                            eventClick={this.handleEventClick}
                            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                            locale={ptBR} />
                    </div>
                </Container>
            </>
        );
    }



    handlePreviousMonth = () => {
        let calendarApi = this.calendarRef.current.getApi();
        calendarApi.prev();
        console.log(calendarApi.getDate().toISOString());
    };

    handleNextMonth = () => {
        let calendarApi = this.calendarRef.current.getApi();
        calendarApi.next();
        console.log(calendarApi.getDate().toISOString());
    };

    handleDateSelect = (selectInfo) => {
        console.log('handleDateSelect');
        let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar

        calendarApi.unselect() // clear date selection

        if (title) {
            calendarApi.addEvent({
                id: Math.random(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            })
        }
    }

    handleDateClick = (selectInfo) => {
        console.log('handleDateClick');

        let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar

        calendarApi.unselect() // clear date selection

        if (title) {
            calendarApi.addEvent({
                id: Math.random(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            })
        }
    };

    handleEventClick = (clickInfo) => {
        // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        //     clickInfo.event.remove()
        // }
        console.log('handleEventClick')
        console.log('Event.id: ' + clickInfo.event.id)
        console.log('Event.title: ' + clickInfo.event.title)
        console.log('Event.start: ' + clickInfo.event.start)
        console.log('Coordinates: ' + clickInfo.jsEvent.pageX + ',' + clickInfo.jsEvent.pageY)
        console.log('View: ' + clickInfo.view.type)
    }

    handleEvents = (events) => {

        console.log('handleEvents')

        this.setState({
            currentEvents: events
        })
    }
}

function renderEventContent(eventInfo) {
    return (
        <>
            <b>{eventInfo.timeText}</b>
            <i>{eventInfo.event.title}</i>
        </>
    )
}