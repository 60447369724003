import React from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import RouteGuard from './components/RouteGuard';

import Login from './pages/Login'
import EsqueciSenha from './pages/EsqueciSenha'
import Home from './pages/Home'
import Agenda from './pages/Agenda'
import Arquivo from './pages/Arquivo'
import Calendario from './pages/Calendario'
import CalendarExample from './pages/CalendarExample'
import Calendar from './pages/Calendar'
import Catalogo from './pages/Catalogo'
import Relatorio from './pages/Relatorio'
import Simulacao from './pages/Simulacao'
import Projeto from './pages/Projeto'
import ProjetoEquipe from './pages/ProjetoEquipe'
import Contatos from './pages/Contatos'
import PowerBi from './pages/PowerBi'
import Pagamento from './pages/Pagamento'
import PagamentoAplicativo from './pages/PagamentoAplicativo'

import DadosPessoais from './pages/MinhasInformacoes/DadosPessoais'
import CerfificadoEspecifico from './pages/MinhasInformacoes/ConhecimentoEspecifico'
import DadosBancarios from './pages/MinhasInformacoes/DadosBancarios'
import ExperienciaProfissional from './pages/MinhasInformacoes/ExperienciaProfissional'
import Academica from './pages/MinhasInformacoes/Academica'
import Idioma from './pages/MinhasInformacoes/Idioma'
import Curso from './pages/MinhasInformacoes/Curso'
import Certificacao from './pages/MinhasInformacoes/Certificacao'
import PassaportVistos from './pages/MinhasInformacoes/PassaportVistos'
import ResumoGeral from './pages/MinhasInformacoes/ResumoGeral'
import Noticias from "./pages/Noticias";
import NewsList from "./pages/NewsList";
import NoticiaDetalhe from "./pages/NoticiaDetalhe";
import AdministrativeContact from "./pages/AdministrativeContact";

import MinhaSenha from './pages/MinhaSenha'
import Manutencao from './pages/Manutencao';

function Router() {
    return (
        <BrowserRouter>
            <Switch>
                <RouteGuard exact path="/" component={Login} isPublic />
                <RouteGuard path="/esquecisenha" component={EsqueciSenha} isPublic />
                <RouteGuard path="/manutencao" component={Manutencao} isPublic />
                
                <RouteGuard path="/home" component={Home} />
                <RouteGuard path="/agenda" component={Agenda} />
                <RouteGuard path="/arquivo" component={Arquivo} />
                <RouteGuard path="/calendario" component={Calendario} />
                <RouteGuard path="/calendar" component={Calendar} />
                <RouteGuard path="/calendarexample" component={CalendarExample} />
                <RouteGuard path="/catalogo" component={Catalogo} />
                <RouteGuard path="/relatorio" component={Relatorio} />
                <RouteGuard path="/simulacao" component={Simulacao} />
                <RouteGuard path="/pagamento" component={Pagamento} />
                <RouteGuard path="/pagamentoAplicativo" component={PagamentoAplicativo} />
                <RouteGuard path="/projeto" component={Projeto} />
                <RouteGuard path="/projetoequipe" component={ProjetoEquipe} />
                <RouteGuard path="/contato" component={Contatos} />
                <RouteGuard path="/dadospessoais" component={DadosPessoais} />
                <RouteGuard path="/certificadoespecifico" component={CerfificadoEspecifico} />
                <RouteGuard path="/dadosbancarios" component={DadosBancarios} />
                <RouteGuard path="/experienciaprofissional" component={ExperienciaProfissional} />
                <RouteGuard path="/academica" component={Academica} />
                <RouteGuard path="/idioma" component={Idioma} />
                <RouteGuard path="/curso" component={Curso} />
                <RouteGuard path="/certificacao" component={Certificacao} />
                <RouteGuard path="/passaporte" component={PassaportVistos} />
                <RouteGuard path="/resumogeral" component={ResumoGeral} />
                <RouteGuard path="/minhasenha" component={MinhaSenha} />
                <RouteGuard path="/noticias" component={Noticias} />
                <RouteGuard path="/noticia-detalhe/:id" component={NoticiaDetalhe} />
                <RouteGuard path="/newslist" component={NewsList} />
                <RouteGuard path="/administrative-contacts" component={AdministrativeContact} />

                <RouteGuard path="*" component={() => <Redirect to="/" />} isPublic />
            </Switch>
        </BrowserRouter>
    );
}

export default Router;
