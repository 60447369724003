import React, { useState, useEffect, useRef } from 'react'
import './style.scss'

import { ContainerModal } from './../../components/Layout'
import { Button, Form, Input, Select, ValidacaoYup, SelectAsync } from './../../components/Form'

import { useLayout } from './../../context/Layout'
import moment from 'moment'
import api from './../../api'

function Projeto() {

    const { setLoadFull } = useLayout()

    const formRef = useRef(null)

    const [items, setItems] = useState([])
    const [dataMesAnoBusca, setMesAnoBusca] = useState({})

    const [dataProjeto, setDataProjeto] = useState({})
    const [statusProjeto, setStatusProjeto] = useState({})
    const [statusEscolhido, setStatusEscolhido] = useState({})

    let columnRef = useRef([])

    useEffect(() => {
        onBuscaDados()
    }, [])

    async function onBuscaDados() {
        const status = await api.post('projetos/comboStatusProjeto', {})
        setStatusProjeto(status.data.value)
    }

    async function onBuscaDadosSelects(data) {

        // gambis
        if(data.value === statusEscolhido.value) return

        debugger
        if (data) {            

            setStatusEscolhido(data)

            const projeto = await api.post('projetos/comboProjeto', data)
            setDataProjeto(projeto.data.value)

            formRef.current.setData({
                status: {label: data.label, value:data.value}
            })
        }
    }

    async function onPesquisar(data) {
        setLoadFull(true)

        const resp = await api.post('projetosequipes/equipeProjeto', { projeto: data.projeto, mes: data.mes, ano: data.ano })

        columnRef.current = resp.data.column
        setItems(resp.data.value)
        setLoadFull(false)
    }

    function TopSearch() {

        return (
            <>

                <div className="fundoPesquisa">
                    <Form
                        setRef={formRef}
                        onSubmit={onPesquisar}
                        className='col-xl-12 col-lg-12'
                        validacao={{
                            projeto: ValidacaoYup.string().required(),
                            mes: ValidacaoYup.string().required(),
                            ano: ValidacaoYup.string().min(1).required()
                        }}
                    >
                        <div className="itemPesquisa">
                            {/* <SelectAsync name='projeto' col='col-lg-5' titulo='Selecione o Projeto' router='projetos/comboProjeto' /> */}

                            {/* <SelectAsync
                                name='status'
                                col='col-lg-2'
                                titulo='Selecione o Status'
                                labelValue='status_projeto'
                                router='projetos/comboStatusProjeto'
                                onChange={onBuscaDadosSelects}
                            /> */}

                            <Select
                                name='status'
                                col='col-lg-2'
                                titulo='Selecione o Status'
                                labelValue='status_projeto'
                                options={statusProjeto}
                                onChange={onBuscaDadosSelects}
                            />

                            <Select
                                name='projeto'
                                col='col-lg-4'
                                titulo='Selecione o Projeto'
                                labelValue='nome_projeto'
                                options={dataProjeto}
                            />

                            {/* 
                            <SelectAsync
                                name='projeto'
                                col='col-lg-4'
                                titulo='Selecione o Projeto'
                                labelValue='nome_projeto'
                                router='projetos/comboProjeto'
                                routerParams={{
                                    data: dataMesAnoBusca
                                }}
                            /> */}

                            <SelectAsync name='mes' col='col-lg-2' titulo='Selecione mês' router='projetos/comboMes' />
                            <SelectAsync name='ano' col='col-lg-2' titulo='Selecione ano' router='projetos/comboAno' />
                            <div col='col-lg-3'>
                                <Button name='FILTRAR' />
                            </div>
                        </div>
                    </Form>
                </div>

            </>
        )
    }

    return (
        <>
            <ContainerModal
                routerSearch='projetosequipes/equipeProjeto'
                title='Programação da Equipe do Projeto'
                showButtonNovo={false}
                showButtonExcluir={false}
                showButtonEditar={false}
                showButtonPagination={false}
                topSearch={TopSearch}
                dataExterno={items}
                campos={columnRef.current}
                tableColumnColor={true}
            >

            </ContainerModal>
        </>
    )
}

export default Projeto