import React from 'react'
import './style.scss'

import { ContainerModal } from './../../../components/Layout'
import { SelectAsync, ValidacaoYup } from './../../../components/Form'

function Idioma() {

    return (
        <ContainerModal
            route='idiomas'
            title='Idiomas'
            showButtonPagination={false}
            validacao={{
                Cod_Idioma: ValidacaoYup.string().min(1).required(),
                Nivel_ConsIdioma: ValidacaoYup.string().min(1).required(),
            }}
            showTopNavigation={true}
            campos={['Idioma', 'Fluência']}
            campos={[
                { nome: 'Idioma' },
                { nome: 'Fluência' },
            ]}
        >

            <SelectAsync name='Cod_Idioma' col='col-lg-12' titulo='Idioma' router='idiomas/comboIdioma' />
            <SelectAsync name='Nivel_ConsIdioma' col='col-lg-12' titulo='Fluência' router='idiomas/comboFluencia' />

        </ContainerModal>
    )
}

export default Idioma