import React, { useState, useEffect, useRef } from 'react'

import './style.scss'

import { Container, Card, mensagemSucesso } from './../../../components/Layout'
import { Form, Input, Data, SelectAsync, Select, CNPJCPF, Telefone, ButtonSave, CEP, ValidacaoYup } from './../../../components/Form'

import { useLayout } from './../../../context/Layout'
import api from './../../../api'

function DadosBancarios() {

    const { setLoadFull } = useLayout()
    const [load, setLoad] = useState(false)
    const [validacao, setValidacao] = useState('')
    const [dadosCC, setDadosCC] = useState({})

    const formRef = useRef(null)

    useEffect(() => {
        setLoadFull(true)
        onBuscaDados()
        setLoadFull(false)
    }, [])

    async function onBuscaDados() {
        const resp = await api.post('bancarios/edit', {})
        formRef.current.setData(resp.data.value)
        setDadosCC(resp.data.value)
    }

    async function onSave(data) {
        setLoadFull(true)

        const { Cod_Banco, Num_Agencia, Num_CC } = data

        if (Cod_Banco === 341) {

            const resp = await api.post('bancarios/update', {
                dadosCC: dadosCC,
                dados: data
            })

            if (resp.data?.erro) {
                setLoadFull(false)
                setValidacao(resp.data.erro.message)
                return
            }

            setValidacao('')
            mensagemSucesso('Resumo atualizado com sucesso!')

        }
        else {
            setValidacao('Apenas o Banco Itau está disponível para atualização!')
        }

        setLoadFull(false)

    }

    return (
        <Container
            showTopNavigation={true}
            showSearch={false}
        >
            <Card
                nome='Dados Bancários'
            >
                <Form
                    onSubmit={onSave}
                    setRef={formRef}
                    validacao={{}}
                >

                    <div className='col-lg-6 m-top-16'>
                        {validacao.length > 0 && <div style={{ marginTop: 20 }} className="validaRetorno">{validacao}</div>}
                    </div>

                    <SelectAsync name='Cod_Banco' col='col-lg-4' titulo='Banco' router='bancarios/comboBanco' />
                    <Input name='Num_Agencia' col='col-lg-4' titulo='Agência' placeholder='9999-9' />
                    <Input name='Num_CC' col='col-lg-4' titulo='Conta corrente' placeholder='99.999-9' />

                    <div className='col-lg-6 m-top-16'>
                        <ButtonSave name='Salvar' />
                    </div>

                </Form>
            </Card>
        </Container>
    )
}

export default DadosBancarios