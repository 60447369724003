import React from 'react'

import alertConfirm from 'react-alert-confirm'
import { FiTrash2 } from 'react-icons/fi'

import api from './../../api'
import { mensagemSucesso } from './../../components/Layout'
import { useLayout } from './../../context/Layout'

function Deletar({ item, onBuscaDados, route }) {
    const { setLoadFull } = useLayout()

    async function onDeletar(id, mensagem, route) {

        alertConfirm({
            type: 'confirm',
            title: mensagem,
            content: 'Clicando em OK você deletará este item para sempre.',
            lang: 'en',
            okText: 'Sim',
            cancelText: 'Cancelar',
            zIndex: 999999,
            onOk: async () => {

                setLoadFull(true)

                if (route)
                    await api.delete(`${route}/${id}`)

                if (onBuscaDados)
                    onBuscaDados()

                setLoadFull(false)

                mensagemSucesso('Item deletado com sucesso!')
            },
            onCancel: () => { }
        })
    }

    return (
        <div className='buttonTableClass' style={{ marginLeft: 10 }} data-rh='Deletar este Item'>
            <FiTrash2 onClick={() => onDeletar(item.id, 'Deseja deletar este Item?', route)} />
        </div>
    )
}

export default Deletar