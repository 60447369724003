export default {
    primary: '#0D2B42',
    secondary: '#616163',
    registrar: '#4d4d4d',
    branco: '#f1f3f5',
    button: '#0D2B42',
    confirmar: '#d0d1d4',
    modal: '#0D2B42',
    box: '#616163',
    boxStatusGreen: '#65cb81',
    boxStatusRed: '#d70916',
}