import React, { useState, useEffect, useRef } from 'react'
import './style.scss'

import { ContainerModal, Container, Card, Modal, Tab, mensagemSucesso } from './../../components/Layout'
import { Select, Input, Form, ButtonSave, SelectAsync, Data, ButtonSair, ButtonDelete, ValidacaoYup } from './../../components/Form'
import { SemRegistro, MoreRegistro, Deletar, Editar, ButtonGenerico, Table } from './../../components/Page'

import { FiPlusCircle, FiXCircle } from 'react-icons/fi'

import { useLayout } from './../../context/Layout'
import api from './../../api'

function Arquivo() {

    const [selectedFile, setSelectedFile] = useState(null)
    const [isFilePicked, setIsFilePicked] = useState(false)

    const changeHandler = (event) => {
        console.log('event', event.target.files[0])

        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);

    };

    function onSaveData(data) {

        console.log('selectedFile', selectedFile)

        if (selectedFile)
            data.documento = selectedFile

        return data
    }

    return (
        <ContainerModal
            route='arquivos'
            title='Arquivos'
            showButtonEditar={false}
            showButtonPagination={false}
            onSaveBefore={onSaveData}
            useFormData={true}
            validacao={{
                nome_exibicao: ValidacaoYup.string().min(3).required(),
            }}
            campos={[
                { nome: 'Nome Exibição' },
                { nome: 'Perfil' },
            ]}
        >

            <Input name='nome_exibicao' col='col-lg-12' titulo='Nome de Exibição do Arquivo' placeholder='Digite o nome' />

            <div style={{ display: 'flex', flexDirection: 'row' }} >

                {/* <SelectAsync name='perfil_usuario' col='col-lg-8' titulo='Perfil' router='arquivos/comboPerfil' /> */}
                <Select name='perfil_usuario' col='col-lg-4' titulo='Perfil' options={[
                    { value: 'CLT', label: 'CLT' },
                    { value: 'Socio', label: 'Socio' },
                    { value: 'Geral', label: 'Geral' },
                ]} />

                <Select name='principal_sn' col='col-lg-4' titulo='Arquivo Principal?' options={[
                    { value: 'S', label: 'Sim' },
                    { value: 'N', label: 'Não' }
                ]} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px', alignItems: 'center' }} >
                <input type="file" name="file" onChange={changeHandler} />
                {isFilePicked ? (
                    <div>
                        <p>Filename: {selectedFile.name}</p>
                        <p>Filetype: {selectedFile.type}</p>
                        <p>Size in bytes: {selectedFile.size}</p>
                        <p>
                            lastModifiedDate:{' '}
                            {selectedFile.lastModifiedDate.toLocaleDateString()}
                        </p>
                    </div>
                ) : (
                        <p>Selecione o arquivo desejado</p>
                    )}

            </div>

        </ContainerModal>
    )
}

export default Arquivo