import React from 'react'
import './style.scss'

import { ContainerModal } from './../../../components/Layout'
import { SelectAsync, ValidacaoYup } from './../../../components/Form'

function Academica() {

    return (
        <ContainerModal
            route='academicos'
            title='Formação Acadêmica'
            showButtonPagination={false}
            validacao={{
                Cod_NivelGrad: ValidacaoYup.string().min(1).required(),
                Cod_Curso: ValidacaoYup.string().min(1).required(),
                Cod_InstEnsino: ValidacaoYup.string().min(1).required(),
            }}
            showTopNavigation={true}
            campos={[
                { nome: 'Nível' },
                { nome: 'Curso' },
                { nome: 'Instituição de Ensino' },
            ]}
        >

            <SelectAsync name='Cod_NivelGrad' col='col-lg-12' titulo='Nível' router='academicos/comboNivelGraduacao' />
            <SelectAsync name='Cod_Curso' col='col-lg-12' titulo='Curso' router='academicos/comboCurso' />
            <SelectAsync name='Cod_InstEnsino' col='col-lg-12' titulo='Instituição de Ensino' router='academicos/comboInstituicaoEnsino' />
            <SelectAsync name='Cod_AreaEspec' col='col-lg-12' titulo='Área de especialização' router='academicos/comboAreaEspecializacao' />
            <SelectAsync name='AnoForm_Curso' col='col-lg-12' titulo='Ano de conclusão' router='academicos/comboAno' />

        </ContainerModal>
    )
}

export default Academica