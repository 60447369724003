// src/pages/NewsList/index.js
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import YouTube from 'react-youtube';
import PageTracker from '../../components/Page/PageTracker';
import VideoTracker from '../../components/VideoTracker';
import 'leaflet/dist/leaflet.css';
import './style.scss'
import { useAuth } from '../../context/Auth';
import { Container } from './../../components/Layout';
import { Section, Title, Line, Link } from './../../components/Page/News/Components';
import api from './../../api';
import { Table } from './../../components/Page';
import CalendarWithEditor from '../../components/CalendarWithEditor';
import AdministrativeContactModal from '../../components/AdministrativeContactModal';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import L from 'leaflet';

function NewsList() {
    const { getUser } = useAuth();
    const [videoData, setVideoData] = useState(null);
    const [featuredNews, setFeaturedNews] = useState({ first: null, second: null, third: null });
    const [news, setNews] = useState([]);
    const [announcements, setAnnouncements] = useState([]);
    const [birthdays, setBirthdays] = useState([]);
    const [knowledgeLibrary, setKnowledgeLibrary] = useState([]);
    const [humanResources, setHumanResources] = useState([]);
    const [ourFlighs, setOurFlighs] = useState([]);
    const [eventsAndTrainings, setEventsAndTrainings] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
    const [administrativeContacts, setAdministrativeContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [showContactModal, setShowContactModal] = useState(false);
    const [projectsMap, setProjectsMap] = useState({ projects: [], locations: [] });
    const [mapZoom, setMapZoom] = useState(1);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [mapInstance, setMapInstance] = useState(null);

    const history = useHistory();
    const videoTracker = VideoTracker({ videoData });

    const opts = {
        height: '440',
        width: '100%',
        playerVars: {
        autoplay: 0,
        },
    };

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('pt-BR', { month: 'long' });
    };

    const handlePreviousMonth = () => {
        const newMonth = currentMonth === 1 ? 12 : currentMonth - 1;
        setCurrentMonth(newMonth);
    };

    const handleNextMonth = () => {
        const newMonth = currentMonth === 12 ? 1 : currentMonth + 1;
        setCurrentMonth(newMonth);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responses = await Promise.allSettled([
                    api.post('/home-contents/latest-video'),
                    api.post('/home-contents/featured'),
                    api.post('/home-contents/news'),
                    api.post('/home-contents/announcements'),
                    api.post('/homes/birthdays', { month: currentMonth }),
                    api.post('/home-contents/knowledge-library'),
                    api.post('/home-contents/human-resources'),
                    api.post('/home-contents/ourFlighs'),
                    api.post('/home-contents/eventsAndTrainings'),
                    api.post('/administrative-contacts/dados'),
                    api.post('/projetos/maps'),
                ]);
        
                const [
                    videoResponse,
                    featuredResponse,
                    newsResponse,
                    announcementsResponse,
                    birthdaysResponse,
                    knowledgeLibraryResponse,
                    humanResourcesResponse,
                    ourFlighsResponse,
                    eventsAndTrainingsResponse,
                    administrativeContactsResponse,
                    projectsMapResponse
                ] = responses;
        
                if (videoResponse.status === 'fulfilled') setVideoData(videoResponse.value.data);
                if (featuredResponse.status === 'fulfilled') setFeaturedNews(featuredResponse.value.data);
                if (newsResponse.status === 'fulfilled') setNews(newsResponse.value.data);
                if (announcementsResponse.status === 'fulfilled') setAnnouncements(announcementsResponse.value.data);
                if (birthdaysResponse.status === 'fulfilled') setBirthdays(birthdaysResponse.value.data);
                if (knowledgeLibraryResponse.status === 'fulfilled') setKnowledgeLibrary(knowledgeLibraryResponse.value.data);
                if (humanResourcesResponse.status === 'fulfilled') setHumanResources(humanResourcesResponse.value.data);
                if (ourFlighsResponse.status === 'fulfilled') setOurFlighs(ourFlighsResponse.value.data);
                if (eventsAndTrainingsResponse.status === 'fulfilled') setEventsAndTrainings(eventsAndTrainingsResponse.value.data);
                if (administrativeContactsResponse.status === 'fulfilled') setAdministrativeContacts(administrativeContactsResponse.value.data.value);
                if (projectsMapResponse.status === 'fulfilled') {
                    setProjectsMap({
                        projects: projectsMapResponse.value.data.value.projects,
                        locations: projectsMapResponse.value.data.value.locations
                    });
                }
        
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        
        fetchData();
    }, [currentMonth]);

    const handleNewsClick = (id) => {
        history.push(`/noticia-detalhe/${id}`);
    };

    // Function to extract YouTube video ID from URL
    const getYouTubeId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url?.match(regExp);
        return match && match[2].length === 11 ? match[2] : null;
    };

    const stripHtml = (html) => {
        const tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    };

    useEffect(() => {
        console.log('projectsMap', projectsMap);
    }, [projectsMap]);

    const handleFileDownload = (fileUrl) => {
        console.log('fileUrl', fileUrl);
        window.open(fileUrl, '_blank');
    };

    const handleContactClick = async (contactId) => {
        try {
            const response = await api.get(`/administrative-contacts/${contactId}`);
            setSelectedContact(response.data.value);
            setShowContactModal(true);
        } catch (error) {
            console.error('Error fetching contact details:', error);
        }
    };

    const createCustomHtmlIcon = (municipio) => {
        const truncatedMunicipio = municipio.length > 7 ? municipio.substring(0, 7) + '...' : municipio;
        return new L.DivIcon({
            html: `<div style="background-color: #09324C; width: 100px; height: 70px; border-radius: 10%; display: flex; justify-content: center; align-items: center;">
            <span style="color: #2AAAE1; font-size: 14px;">${truncatedMunicipio}</span>
            </div>`,
            className: 'custom-html-icon',
        });
    };

    const isValidCoordinates = (lat, lng) => {
        return lat !== null && lng !== null && !isNaN(lat) && !isNaN(lng);
    };

    const groupByCountry = (projects) => {
        return projects.reduce((acc, project) => {
            if (!project.País || !isValidCoordinates(project.Latitude, project.Longitude)) return acc;
            
            if (!acc[project.País]) {
                acc[project.País] = {
                    consultores: 0,
                    projetos: 0,
                    latitude: project.Latitude,
                    longitude: project.Longitude
                };
            }
            
            acc[project.País].consultores += project.QtdeConsultores || 0;
            acc[project.País].projetos += 1;
            
            return acc;
        }, {});
    };

    const createCountryIcon = (country, data, locationInfo) => {
        const projetosText = data.projetos === 1 ? 'projeto' : 'projetos';
        const consultoresText = locationInfo.consultores === 1 ? 'consultor' : 'consultores';
        
        return new L.DivIcon({
            html: `
                <div class="country-cluster">
                    <span style="color: #2AAAE1; font-weight: bold;">${country}</span>
                    <span>${data.projetos} ${projetosText}</span>
                    <span>${locationInfo.consultores} ${consultoresText}</span>
                </div>
            `,
            className: 'custom-html-icon'
        });
    };

    const handleMapZoom = (e) => {
        setMapZoom(e.target._zoom);
    };

    // Adicione esta função de distribuição de marcadores
    const distributeMarkers = (markers) => {
        // Aumentando o raio para 2km para maior separação
        const baseRadius = 2000; 
        
        // Se houver apenas 2 marcadores
        if (markers.length === 2) {
            return markers.map((project, index) => {
                const offset = index === 0 ? -baseRadius : baseRadius;
                return {
                    ...project,
                    Latitude: parseFloat(project.Latitude),
                    Longitude: parseFloat(project.Longitude) + (offset / (111300 * Math.cos(project.Latitude * (Math.PI / 180))))
                };
            });
        }
        
        // Para 3 ou mais marcadores
        return markers.map((project, index) => {
            if (index === 0) return project;
            
            // Ajusta o raio baseado no número de marcadores
            const radius = baseRadius * (1 + (markers.length > 5 ? 0.2 * index : 0));
            const angle = (2 * Math.PI * index) / markers.length;
            
            // Calcula nova posição usando coordenadas polares
            const lat = parseFloat(project.Latitude) + (radius / 111300) * Math.cos(angle);
            const lng = parseFloat(project.Longitude) + (radius / (111300 * Math.cos(project.Latitude * (Math.PI / 180)))) * Math.sin(angle);
            
            return {
                ...project,
                Latitude: lat,
                Longitude: lng
            };
        });
    };

    // Adicione esta função para manipular o clique no marcador
    const handleMarkerClick = (project) => {
        setSelectedMarker(project);
        if (mapInstance) {
            mapInstance.leafletElement.invalidateSize();
        }
    };

    // Adicione este useEffect para monitorar mudanças no zoom e dados
    useEffect(() => {
        if (mapInstance && projectsMap.length > 0) {
            mapInstance.leafletElement.invalidateSize();
        }
    }, [mapZoom, projectsMap, mapInstance]);

    const groupByCity = (projects) => {
        return projects.reduce((acc, project) => {
            if (!project.Municipio || !isValidCoordinates(project.Latitude, project.Longitude)) return acc;
            
            const cityKey = `${project.Municipio}-${project.País}`;
            if (!acc[cityKey]) {
                acc[cityKey] = {
                    municipio: project.Municipio,
                    pais: project.País,
                    consultores: 0,
                    projetos: [],
                    latitude: project.Latitude,
                    longitude: project.Longitude
                };
            }
            
            acc[cityKey].consultores += project.QtdeConsultores || 0;
            acc[cityKey].projetos.push(project);
            
            return acc;
        }, {});
    };

    const createCityIcon = (city, data, locationInfo) => {
        const truncatedCity = city.length > 7 ? city.substring(0, 7) + '...' : city;
        const projetosText = data.projetos.length === 1 ? 'projeto' : 'projetos';
        const consultoresText = locationInfo.consultores === 1 ? 'consultor' : 'consultores';
        
        return new L.DivIcon({
            html: `
                <div style="background-color: #09324C; width: 100px; padding: 5px; border-radius: 10%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <span style="color: #2AAAE1; font-size: 14px;">${truncatedCity}</span>
                    <span style="color: white; font-size: 12px;">${data.projetos.length} ${projetosText}</span>
                    <span style="color: white; font-size: 12px;">${locationInfo.consultores} ${consultoresText}</span>
                </div>
            `,
            className: 'custom-html-icon'
        });
    };

    // Modifique a função renderMarkers
    const renderMarkers = () => {
        if (mapZoom < 4) {
            // Show country-level aggregation
            const countryGroups = groupByCountry(projectsMap.projects);
            return Object.entries(countryGroups).map(([country, data], index) => {
                const locationInfo = projectsMap.locations.find(
                    loc => loc.tipo === 'pais' && loc.nome === country
                ) || { consultores: 0 };

                return (
                    <Marker
                        key={`country-${index}`}
                        position={[data.latitude, data.longitude]}
                        icon={createCountryIcon(country, data, locationInfo)}
                    >
                    </Marker>
                );
            });
        } else if (mapZoom < 9) {
            // Show city-level aggregation
            const validProjects = projectsMap.projects.filter(project => 
                isValidCoordinates(project.Latitude, project.Longitude)
            );
            const cityGroups = groupByCity(validProjects);

            return Object.entries(cityGroups).map(([cityKey, cityData], index) => {
                const locationInfo = projectsMap.locations.find(
                    loc => loc.tipo === 'municipio' && loc.nome === cityData.municipio
                ) || { consultores: 0 };

                return (
                    <Marker
                        key={`city-${index}`}
                        position={[cityData.latitude, cityData.longitude]}
                        icon={createCityIcon(cityData.municipio, cityData, locationInfo)}
                    >
                    </Marker>
                );
            });
        } else {
            // Show individual projects when zoomed in
            const validProjects = projectsMap.projects.filter(project => 
                isValidCoordinates(project.Latitude, project.Longitude)
            );

            // Agrupar projetos por cidade
            const projectsByCity = validProjects.reduce((acc, project) => {
                const cityKey = `${project.Municipio}-${project.País}`;
                if (!acc[cityKey]) {
                    acc[cityKey] = [];
                }
                acc[cityKey].push(project);
                return acc;
            }, {});

            // Distribuir e renderizar marcadores
            return Object.values(projectsByCity).flatMap(cityProjects => {
                const distributedProjects = cityProjects.length > 1 
                    ? distributeMarkers(cityProjects)
                    : cityProjects;

                return distributedProjects.map((project, index) => (
                    <Marker
                        key={`project-${index}-${project.Latitude}-${project.Longitude}`}
                        position={[project.Latitude, project.Longitude]}
                        icon={createCustomHtmlIcon(project.Municipio)}
                        onClick={() => handleMarkerClick(project)}
                    >
                        <Popup>
                            <div style={{ minWidth: '200px' }}>
                                <strong style={{ fontSize: '16px' }}>{project.Empresa}</strong><br />
                                <strong>Proposta:</strong> {project.Proposta || 'N/A'}<br />
                                <strong>Empresa:</strong> {project.Empresa || 'N/A'}<br />
                                <strong>Cidade:</strong> {project.Municipio || 'N/A'}<br />
                                <strong>País:</strong> {project.País || 'N/A'}<br />
                            </div>
                        </Popup>
                    </Marker>
                ));
            });
        }
    };

    return (
        <>
            <PageTracker pageUrl="/noticias" />
            <Container classAdd='newsClass' showSearch={false} loginBottom='45px'>
                <Section backgroundColor="#FFFFFF">
                    <div style={{ textAlign: 'center' }}>
                        <Title color="#09324C" fontSize="24px">Destaques Aquila</Title>
                    </div>
                    <Line />
                    <div className="row mb-6">
                        <div className="col-6" style={{marginLeft: '40px'}}>
                            <YouTube
                                videoId={videoData ? getYouTubeId(videoData.link) : ''}
                                opts={opts}
                                className="youtube-player"
                                onStateChange={videoTracker.handleVideoState}
                            />
                        </div>
                        <div className="col-6 d-flex flex-column align-items-center" style={{marginLeft: '-40px'}}>
                            <div className="row mb-3 w-100 justify-content-center">
                                <div className="col-12 featured-news-container d-flex justify-content-center">
                                    {featuredNews.first && (
                                        <div 
                                            className="featured-news-square" 
                                            onClick={() => handleNewsClick(featuredNews.first.id)}
                                        >
                                            <img src={featuredNews.first.img_mini_url} alt={featuredNews.first.title} />
                                        </div>
                                    )}
                                    {featuredNews.second && (
                                        <div 
                                            className="featured-news-square"
                                            onClick={() => handleNewsClick(featuredNews.second.id)}
                                        >
                                            <img src={featuredNews.second.img_mini_url} alt={featuredNews.second.title} />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row w-100 justify-content-center">
                                <div className="col-12 d-flex justify-content-center">
                                    {featuredNews.third && (
                                        <div 
                                            className="featured-news-rectangle"
                                            onClick={() => handleNewsClick(featuredNews.third.id)}
                                        >
                                            <img src={featuredNews.third.img_mini_url} alt={featuredNews.third.title} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <Title color="#09324C" fontSize="24px">Notícias</Title>
                    </div>
                    <div className="news-grid">
                        {news.map((newsItem) => (
                            <div 
                                key={newsItem.id} 
                                className="news-item"
                                onClick={() => handleNewsClick(newsItem.id)}
                            >
                                <div className="title-container">
                                    <Link color="#09324C" fontWeight="100">{newsItem.title}</Link>
                                </div>
                                <div className="image-container">
                                    <img 
                                        src={newsItem.img_mini_url} 
                                        alt={newsItem.title}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </Section>
                <Section backgroundColor="#F1F1F1">
                    <div className="row">
                        <div className="col-6">
                            <Title color="#09324C" fontSize="24px">Comunicados</Title>
                            <div className="announcements-container">
                                {announcements.map((announcement) => (
                                    <div 
                                        key={announcement.id} 
                                        className="announcement-item"
                                        onClick={() => handleNewsClick(announcement.id)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <div className="title-wrapper">
                                            <div className="triangle-icon" />
                                            <Link color="#09324C" fontWeight="600">
                                                {announcement.title}
                                            </Link>
                                        </div>
                                        <p>{stripHtml(announcement.text_mini)}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        { false && (
                            <div className="col-6">
                                <Title color="#09324C" fontSize="24px">Atuação Aquila</Title>
                                <div className="map-container">
                                    <Map 
                                        ref={(ref) => setMapInstance(ref)}
                                        center={[0, 0]} 
                                        zoom={2} 
                                        style={{ height: '400px', width: '100%' }}
                                        onZoom={handleMapZoom}
                                        onMoveEnd={() => mapInstance?.leafletElement.invalidateSize()}
                                    >
                                        <TileLayer
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        />
                                        <MarkerClusterGroup
                                            spiderfyOnMaxZoom={true}
                                            showCoverageOnHover={false}
                                            maxClusterRadius={40}
                                            spiderLegPolylineOptions={{ weight: 1.5, color: '#222', opacity: 0.5 }}
                                            spiderfyDistanceMultiplier={2}
                                            zoomToBoundsOnClick={true}
                                            removeOutsideVisibleBounds={true}
                                            animate={false}
                                            key={`cluster-${mapZoom}`}
                                        >
                                            {renderMarkers()}
                                        </MarkerClusterGroup>
                                    </Map>
                                </div>
                            </div>
                        )}
                    </div>
                </Section>
                <Section backgroundColor="#09324C" showIcon="true">
                    <div className="row">
                        <div className="col-6">
                            <Title color="#FFFFFF" fontSize="24px">Nosso Voo</Title>
                            <div className="our-flighs-container">
                                {ourFlighs.map((fligh) => (
                                    <div 
                                        key={fligh.id} 
                                        className="our-flighs-item"
                                        onClick={() => handleNewsClick(fligh.id)}
                                    >
                                        <div className="title-wrapper">
                                            <div className="triangle-icon" />
                                            <Link color="#FFFFFF" fontWeight="600">
                                                {fligh.title}
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-6">
                            <Title color="#FFFFFF" fontSize="24px">Eventos e Treinamentos</Title>
                            <div className="our-flighs-container">
                                {eventsAndTrainings.map((event) => (
                                    <div 
                                        key={event.id} 
                                        className="our-flighs-item"
                                        onClick={() => handleNewsClick(event.id)}
                                    >
                                        <div className="title-wrapper">
                                            <div className="triangle-icon" />
                                            <Link color="#FFFFFF" fontWeight="600">
                                                {event.title}
                                            </Link>
                                            <span className="text-mini">| {stripHtml(event.text_mini)}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Section>
                <Section backgroundColor="#FFFFFF">
                    <div style={{ textAlign: 'center' }}>
                        <Title color="#09324C" fontSize="24px">Agenda</Title>
                    </div>
                    <div className="calendar-container mb-6">
                        <CalendarWithEditor height={500} />
                    </div>
                </Section>
                <Section backgroundColor="#F1F1F1">
                <div className="row">
                    <div className="col-6">
                        <div className="title-section">
                            <div className="title-item">
                                <Title color="#09324C" fontSize="24px">Biblioteca do Conhecimento</Title>
                                <div className="knowledge-library-container">
                                    {knowledgeLibrary.map((item) => (
                                        <div 
                                            key={item.id} 
                                            className="knowledge-item"
                                            onClick={() => window.open(item.link, '_blank')}
                                        >
                                            <div className="title-wrapper">
                                                <div className="triangle-icon" />
                                                <Link color="#09324C" fontWeight="600">
                                                    {item.title}
                                                </Link>
                                            </div>
                                            {item.text_mini && <p>{stripHtml(item.text_mini)}</p>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {(getUser().perfil_consultor === 'CLT' || getUser().perfil_consultor === 'Marketing') && (
                                <div className="title-item">
                                    <Title color="#09324C" fontSize="24px">Departamento Pessoal</Title>
                                    <div className="knowledge-library-container">
                                        {humanResources.map((item) => (
                                            <div 
                                                key={item.id} 
                                                className="knowledge-item"
                                                onClick={() => handleFileDownload(item.fileUrl)}
                                            >
                                                <div className="title-wrapper">
                                                    <div className="triangle-icon" />
                                                    <Link color="#09324C" fontWeight="600">
                                                        {item.title}
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <div className="title-item">
                                <Title color="#09324C" fontSize="24px">Fale com o Administrativo</Title>
                                <div className="administrative-contacts-container">
                                    {administrativeContacts.map((contact) => (
                                        <div 
                                            key={contact.id} 
                                            className="administrative-contact-item"
                                            onClick={() => handleContactClick(contact.id)}
                                        >
                                            <div className="title-wrapper">
                                                <div className="triangle-icon" />
                                                <Link color="#09324C" fontWeight="600">
                                                    {contact.dados[0]}
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="birthdays-wrapper">
                            <div className="title-container">
                                <Title 
                                    color="#09324C" 
                                    fontSize="18px" 
                                >
                                    Aniversariantes do Mês
                                </Title>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <button onClick={handlePreviousMonth} style={{ border: 'none', background: 'none', color: '#36749B' }}>
                                        {'<<'}
                                    </button>
                                    <Title color="#36749B">{getMonthName(currentMonth)}</Title>
                                    <button onClick={handleNextMonth} style={{ border: 'none', background: 'none', color: '#36749B' }}>
                                        {'>>'}
                                    </button>
                                </div>
                            </div>
                            <Table
                                trs={() => birthdays.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <img src={item.avatar} alt="Avatar" width={40} height={40} style={{ borderRadius: 4 }} />
                                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
                                                    <span style={{ display: 'flex', flex: 1, color: '#09324C', fontSize: '18', fontWeight: 'bold', fontFamily: 'verdana' }}>{item.nome}</span>
                                                    <small></small>
                                                </div>
                                            </div>
                                        </td>
                                        <td><span style={{fontWeight: 'bold'}}>{item.dia}/{item.mes}</span></td>
                                    </tr>
                                ))}
                            />
                        </div>
                    </div>
                </div>
                </Section>
            </Container>
            <AdministrativeContactModal 
                show={showContactModal}
                contact={selectedContact}
                onHide={() => setShowContactModal(false)}
            />
        </>
    );
}

export default NewsList;