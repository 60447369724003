import React, { useEffect, useRef } from 'react';
import './style.scss'
import './cnpj-cpf.scss'

import { useField } from '@unform/core';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';

function CNPJCPF({ name, col, cpf = false, ...props }) {
    const inputRef = useRef(null);
    const { fieldName, registerField, defaultValue, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            setValue(ref, value) {
                ref.setInputValue(value === null ? '' : value);
            },
            clearValue(ref) {
                ref.setInputValue('');
            },
        });
    }, [fieldName, registerField]);

    let mask = '99.999.999/9999-99'
    let placeholder = '00.000.000/0000-00'
    if (cpf) {
        mask = '999.999.999-99'
        placeholder = '000.000.000-00'
    }

    return (
        <div className={`cInputD ${col}`}>
            <span>{props.titulo}</span>
            <div className="input-group input-group-merge input-group-alternative">
                <ReactInputMask id={name} mask={mask} autoComplete='no' ref={inputRef} defaultValue={defaultValue} {...props} placeholder={placeholder} />
            </div>
            {error && <span className="error-validacao">{error}</span>}
        </div>
    );
}

export default CNPJCPF