import React from 'react'

import { FiEdit } from 'react-icons/fi'

function Editar({ onEditar, NewIcon, newTooltip }) {

    return (
        <div className='buttonTableClass' style={{ marginLeft: 10 }} data-rh={newTooltip ? newTooltip : 'Editar este Item'}>

            {
                NewIcon ?
                    <NewIcon onClick={onEditar} /> :
                    <FiEdit onClick={onEditar} />
            }

        </div>
    )
}

export default Editar