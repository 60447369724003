import React, { useRef, useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useField } from '@unform/core';
import 'react-datepicker/dist/react-datepicker.css';

import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptbr from 'date-fns/locale/pt-BR';
registerLocale('pt-BR', ptbr)

function Data({ name, titulo, groupClass, onChangeValueData = null, col, ...props }) {

    const datepickerRef = useRef(null);
    const { fieldName, registerField, defaultValue, error } = useField(name);
    const [date, setDate] = useState(defaultValue || null);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: datepickerRef.current,
            path: 'props.selected',
            clearValue: (ref) => {
                ref.clear();
            },
            setValue(ref, value) {
                setDate(value || null)
            }
        });
    }, [fieldName, registerField]);

    return (
        <div className={`cInputD ${col}`}>
            <span htmlFor={name}>{titulo}</span>
            <div className={`input-group input-group-merge input-group-alternative ${groupClass}`}>
                <ReactDatePicker
                    ref={datepickerRef}
                    selected={date === null ? date : new Date(date)}
                    onChange={text => {
                        setDate(text)

                        if (onChangeValueData)
                            onChangeValueData(text)
                    }}                    
                    dateFormat="dd/MM/yyyy"
                    placeholderText="00/00/0000"
                    // minDate={new Date()}
                    locale='pt-BR'
                    {...props}
                />
            </div>
            {error && <span className="error-validacao">{error}</span>}
        </div>
    )
}

export default Data