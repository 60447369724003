import React, { useState, useContext, createContext, useEffect } from 'react'
import { useAuth } from './Auth'

const LayoutContext = createContext()

function LayoutProvider({ children }) {
    const [loadFull, setLoadFullAll] = useState(false)

    function modalIsOpen(open) {
        if (open)
            document.body.classList.add('modal-open')
        else
            document.body.classList.remove('modal-open')
    }


    function setLoadFull(full) {

        if (document.body.classList.contains('modal-open'))
            document.body.classList.remove('modal-open')

        setLoadFullAll(full)
    }

    return (
        <LayoutContext.Provider value={{ loadFull, setLoadFull, modalIsOpen }}>
            {children}
        </LayoutContext.Provider>
    )
}

function useLayout() {
    const context = useContext(LayoutContext)
    return context
}

export { LayoutContext, LayoutProvider, useLayout }