import SemRegistro from './SemRegistro'
import MoreRegistro from './MoreRegistro'
import Deletar from './Deletar'
import Editar from './Editar'
import Table from './Table'
import ButtonGenerico from './ButtonGenerico'

export {
    SemRegistro,
    MoreRegistro,
    Deletar,
    Editar,
    Table,
    ButtonGenerico,
}