// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-5-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/resolve-url-loader/index.js??ref--6-oneOf-5-3!../../../node_modules/sass-loader/dist/cjs.js??ref--6-oneOf-5-4!@fullcalendar/core/main.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-5-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../../node_modules/resolve-url-loader/index.js??ref--6-oneOf-5-3!../../../node_modules/sass-loader/dist/cjs.js??ref--6-oneOf-5-4!@fullcalendar/daygrid/main.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".App {\n  text-align: center;\n  display: flex;\n  min-height: 100%;\n  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;\n  font-size: 12px;\n}\n\n.fc-toolbar.fc-header-toolbar {\n  background-color: #0D2B42;\n  padding: 10px;\n  text-transform: uppercase;\n  font-size: 12px;\n  color: #FFFFFF;\n  margin-bottom: 0;\n}\n\n.fc-toolbar.fc-header-toolbar .fc-center {\n  font-size: 12px;\n  display: flex;\n  align-items: center;\n}\n\n.fc-toolbar.fc-header-toolbar .fc-center button {\n  background: none;\n  border: none;\n  box-shadow: none;\n}\n\n.fc-toolbar.fc-header-toolbar .fc-right button, .fc-toolbar.fc-header-toolbar .fc-left button {\n  background: none;\n  border: none;\n}\n\n.fc-button-primary:not(:disabled):active:focus, .fc-button-primary:not(:disabled).fc-button-active:focus {\n  box-shadow: none;\n}\n\n.fc th {\n  vertical-align: middle;\n  text-transform: capitalize;\n  height: 30px;\n  font-weight: 500;\n}\n\n.fc-list-heading .fc-widget-header {\n  height: 30px;\n  vertical-align: middle;\n  padding: 0 12px;\n  margin: 0;\n  text-transform: uppercase;\n}\n\n.atrasado, .andamento, .concluido {\n  padding: 2px;\n  border: none;\n  font-size: 11px;\n}\n\n.atrasado {\n  background-color: #f1d1d0;\n  color: #FFFFFF;\n}\n\n.andamento {\n  background-color: #a4ebf3;\n}\n\n.fc-event .fc-title {\n  color: #000000 !important;\n  font-weight: 530;\n}\n\n.concluido {\n  background-color: #008891;\n  color: #FFFFFF;\n  border: none;\n}\n\n@media screen and (max-width: 600px) {\n  .fc-toolbar.fc-header-toolbar .fc-center h2 {\n    font-size: 12px;\n    line-height: 12px;\n  }\n  .fc-toolbar.fc-header-toolbar {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n  }\n  .fc-toolbar.fc-header-toolbar .fc-right {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n  }\n}", ""]);
// Exports
module.exports = exports;
